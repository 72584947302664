<app-loading-spinner
  *ngIf="ratesFetched || !pageLoad; else showCart"
></app-loading-spinner>
<ng-template #showCart>
  <main *ngIf="!ratesFetched || pageLoad" class="font-mont lg:bg-[#FBFBFB]">
    <section>
      <app-single-details-header
        class="hidden lg:block"
        [storeName]="stores"
      ></app-single-details-header>
    </section>
    <section
      class="block px-6 lg:px-0 mx-0 xl:flex lg:mx-40 mt-2 lg:mt-8 lg:mb-6 shadow-md lg:shadow-none"
    >
      <div class="block lg:hidden">
        <div
          class="py-4 lg:py-0 flex items-center justify-between lg:justify-start"
        >
          <img
            src="../../../assets/svg/back.svg"
            alt="back"
            class="cursor-pointer"
          />
          <p
            class="hidden lg:block font-medium text-zestBlack text-lg font-mont px-4"
          >
            Back
          </p>
          <p class="text-zestBlack block lg:hidden font-semibold text-2xl">
            My Cart
          </p>
          <p class="block lg:hidden text-2xl">&nbsp;</p>
        </div>
      </div>
      <div class="hidden lg:block">
        <div
          *ngIf="openTab === 0; else showBreadCrumbs"
          class="py-4 lg:py-0 flex items-center justify-between lg:justify-start"
        >
          <img
            src="../../../assets/svg/back.svg"
            alt="back"
            class="cursor-pointer"
            (click)="onBackClick()"
          />
          <p
            class="hidden lg:block font-medium text-zestBlack text-lg font-mont px-4"
          >
            Back
          </p>
          <p class="text-zestBlack block lg:hidden font-semibold text-2xl">
            My Cart
          </p>
          <p class="block lg:hidden text-2xl">&nbsp;</p>
        </div>
        <ng-template #showBreadCrumbs>
          <ul
            class="flex mb-0 list-none no-scrollbar lg:flex-wrap pt-1 flex-row lg:w-full overflow-x-scroll touch-pan-x w-full flex-nowrap lg:overflow-hidden"
          >
            <li
              class="mr-2 lg:mr-2 last:mr-0 text-left"
              *ngFor="let tab of tabs; let i = index; let last = last"
            >
              <p
                class="text-xs lg:text-base font-medium cursor-pointer block leading-normal"
                [ngClass]="{ 'text-zestGrey': openTab !== i }"
                (click)="toggleTabs(i, tab)"
              >
                {{ tab }}
                <span
                  class="inline-block mx-3 text-zestGrey"
                  [ngClass]="{ hidden: last }"
                  >&gt;</span
                >
              </p>
            </li>
          </ul>
        </ng-template>
      </div>
    </section>

    <section class="block mx-6 xl:flex lg:mx-40 mt-8 mb-6">
      <div class="px-0 py-0 my-0 rounded xl:pr-6 grow w-full xl:w-8/12">
        <div class="flex items-center">
          <div class="w-full">
            <div
              class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6"
            >
              <form [formGroup]="multiStageForm" class="">
                <div
                  [ngClass]="{ hidden: openTab !== 0, block: openTab === 0 }"
                >
                  <app-single-store-cart
                    [open]="openTab"
                  ></app-single-store-cart>
                </div>
                <div
                  [ngClass]="{ hidden: openTab !== 1, block: openTab === 1 }"
                >
                  <app-delivery-detail
                    [deliveryDetail]="multiStageForm"
                    [availableShipping]="shippingMethods"
                    [order]="createOrderObj()"
                    (shippingValueEmitter)="handleShipping($event)"
                    (orderValueEmitter)="handleOrderValue($event)"
                  ></app-delivery-detail>
                </div>
                <div
                  [ngClass]="{ hidden: openTab !== 2, block: openTab === 2 }"
                >
                  <app-cart-shipping
                    [shipping]="multiStageForm"
                    [availableShipping]="shippingMethods"
                    [regions]="regionOptions"
                    [rates]="deliveryRates"
                    (deliveryValueEmmitter)="handleDeliveryCharge($event)"
                    (formValidEmitter)="handleFormValid($event)"
                    (selectedShippingPartnerEmitter)="handleSelectedShipPartner($event)"
                  ></app-cart-shipping>
                </div>
                <div
                  [ngClass]="{ hidden: openTab !== 3, block: openTab === 3 }"
                >
                  <app-single-store-cart
                    [open]="openTab"
                  ></app-single-store-cart>
                </div>
                <div
                  [ngClass]="{ hidden: openTab !== 4, block: openTab === 4 }"
                ></div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div class="rounded grow w-full xl:w-96" *ngIf="cartItems">
        <div class="bg-white grow lg:py-6 lg:px-8" >
          <p class="pb-6 text-zestBlack text-base lg:text-2xl font-medium">
            Order Summary
          </p>
          <hr class="border-zestNeutral" />
          <div class="flex justify-between items-center py-4">
            <p class="text-zestLabel text-sm lg:text-base">No of Items</p>
            <p class="text-zestLabel text-sm lg:text-base">
              {{ store.reduceCartQuantity() || 0 }}
            </p>
          </div>
          <hr class="border-zestNeutral" />
          <div class="flex justify-between items-center py-4">
            <p class="text-zestLabel text-sm lg:text-base">Subtotal</p>
            <p class="text-zestLabel text-sm lg:text-base">
              {{ store.reduceCartPrice() | currency : "₦ " }}
            </p>
          </div>
          <hr class="border-zestNeutral" />
          <div class="py-4" *ngIf="shippingOption !== 'PICKUP_IN_STORE'">
            <div
              *ngIf="!(deliveryAmount || deliveryRates)"
              class="flex justify-between items-center"
            >
              <p class="text-zestLabel text-sm lg:text-base">Delivery fee</p>
              <p class="text-zestLabel text-sm lg:text-base">Not added yet</p>
            </div>

            <div *ngIf="deliveryAmount || deliveryRates" class="flex justify-between items-center">
              <p class="text-zestLabel text-sm lg:text-base">Delivery fee</p>
              <p
                *ngIf=" deliveryAmount "
                class="text-zestLabel text-sm lg:text-base"
              >
                {{ ( deliveryAmount) | currency : "₦ " }}
              </p>
            </div>


          </div>
          <hr class="border-zestNeutral" />
          <div class="flex justify-between items-center py-4">
            <p class="text-zestBlack text-lg lg:text-2xl font-medium">Total</p>
            <p class="text-zestBlack text-lg lg:text-2xl font-medium">
              {{ this.total() | currency : "₦ " }}
            </p>
          </div>
          <hr class="border-zestNeutral" />
          <p
            *ngIf="!deliveryAmount && receivedValue !== 'PICKUP_IN_STORE'"
            class="text-sm text-zestLabel pt-3 pb-6"
          >
            Delivery fee not included yet
          </p>
          <button
            *ngIf="openTab === 0"
            (click)="toggleTabs(tabs.indexOf('Delivery detail'))"
            [disabled]="orderCreated || deliveryRates || pickup"
            class="bg-zestBlack block text-white rounded-lg py-4 px-8 mt-3 w-full text-sm lg:text-base"
          >
            Proceed to Shipping
          </button>
          <button
            *ngIf="openTab === 1 && receivedValue === 'PICKUP_IN_STORE'"
            (click)="placePickupOrder()"
            [disabled]="receivedValue !== 'PICKUP_IN_STORE'"
            class="bg-zestBlack block text-white rounded-lg py-4 px-8 mt-3 w-full text-sm lg:text-base"
          >
          <mat-spinner [diameter]="18" class="justify-center items-center flex mx-auto text-zestWhite py-2 px-6"
            *ngIf="createPickupOrder; else addProduct"></mat-spinner>
          <ng-template #addProduct>
            <p>Proceed to Payment</p>
          </ng-template>

        </button>
        <button
          *ngIf="openTab === 1 && receivedValue === 'PICKUP_IN_STORE'"
          (click)="placeOrderAndPay()"
          [disabled]="receivedValue !== 'PICKUP_IN_STORE'"
          class="w-[100%] mt-3"
        >
        <button (click)="klump()">
          <img src="../../assets/svg/klump_button.svg" alt="klump-button"/>
        </button>
        </button>
          <button
            *ngIf="openTab === 1 && receivedValue !== 'PICKUP_IN_STORE'"
            (click)="toggleTabs(tabs.indexOf('Shipping'))"
            [disabled]="orderCreated || deliveryRates || !receivedValue"
            class="bg-zestBlack block text-white rounded-lg py-4 px-8 mt-3 w-full text-sm lg:text-base disabled:bg-zestNeutral disabled:text-zestNeutral-300"
          >

            Continue to Shipping
          </button>
          <button
            *ngIf="openTab === 2 && receivedValue !== 'PICKUP_IN_STORE'"
            (click)="placeShipOrder()"
            [disabled]="!deliveryAmount"
            class="bg-zestBlack block text-white rounded-lg py-4 px-8 mt-3 w-full text-sm lg:text-base disabled:bg-zestNeutral disabled:text-zestNeutral-300"
          >
          <mat-spinner [diameter]="18" class="justify-center items-center flex mx-auto text-zestWhite py-2 px-6"
            *ngIf="createPickupOrder; else addProduct"></mat-spinner>
          <ng-template #addProduct>
            <p>Place Order</p>
          </ng-template>

          </button>

          
          <button
            *ngIf="openTab === 3 && receivedValue !== 'PICKUP_IN_STORE'"
            (click)="payWithZest()"
            class="bg-zestBlack block text-white rounded-lg py-4 px-8 mt-3 w-full text-sm lg:text-base"
          >
            Proceed to Payment
          </button>
          <button
          *ngIf="openTab === 3 && receivedValue !== 'PICKUP_IN_STORE'"
          class="w-[100%] mt-3"
        >
        <button (click)="klump()">
          <img src="../../assets/svg/klump_button.svg" alt="klump-button"/>
        </button>
        </button>
          <button
            [routerLink]="['/stores', storeName | lowercase]"
            class="bg-zestBg text-zestBlack border-[#80909D] border text-sm lg:text-base rounded-lg py-4 px-8 mt-3 w-full"
          >
            Continue Shopping
          </button>
          <button [ngClass]="{'invisible': klumpHide}">
            <klump-checkout (btnClick)="pay()"></klump-checkout>
          </button>

        </div>
      </div>
    </section>
    <section class="my-8 px-5 lg:px-[154px] bg-[#F4F6F9]">
      <app-single-store-footer [storeName]="stores"></app-single-store-footer>
    </section>
  </main>
</ng-template>
