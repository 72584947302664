// import { trigger } from '@angular/animations';
import { Location } from '@angular/common';
import {
  AfterViewChecked,
  Component,
  EnvironmentInjector,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { SnackBarService } from 'src/app/shared/services/snackbar.service';
import { StoreService } from 'src/app/shared/services/store.service';
import { environment } from 'src/environments/environment';

import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-single-store-single-product',
  templateUrl: './single-store-single-product.component.html',
  styleUrls: ['./single-store-single-product.component.scss'],
})
export class SingleStoreSingleProductComponent implements OnInit {
  constructor(
    public store: StoreService,
    private route: ActivatedRoute,
    private location: Location,
    private snackBar: SnackBarService,
    private router: Router
  ) {}

  // @ViewChild(CarouselComponent) carousel: CarouselComponent;
  carouselOptions: OwlOptions = {
    loop: true,
    autoWidth: true,
    dots: false,
    mouseDrag: true,
    autoplay: true,
    navSpeed: 500,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1,
      },
    },
    nav: false,
  };

  singleProduct!: {
    productCode: string;
  };

  variantOutOfStock: boolean = false;

  quantityOutOfStock: boolean = false;

  variantStock: number = 0;

  order: string[] = ['size', 'colour', 'length', 'height', 'width'];
  arrangedMeta: any[] = [];

  singleStore!: {
    storename: string;
  };
  optionSelected: string[] = [];
  productVariant: any;

  link: string = '';

  capName!: string;

  productFetched: boolean = true;
  discountPercent: any;
  percent!: number;

  productDetails: any[] = [];
  variantArr: any[] = [];
  selectedPrice!: number;
  selectedNameVariant: string = '';
  available: boolean = true;
  name!: string;

  count: number = 1;
  disable: boolean = false;
  mainImage: any;
  inview: any[] = [];
  @ViewChild('myImage') image: any;
  @ViewChild('containerImage') div: any;

  onBackClick() {
    this.location.back();
  }

  findVariantPrice(optionsArr: any[]) {
    this.selectedNameVariant = optionsArr.join(', ');
    this.productDetails[0].variants?.map((variants: any) => {
      if (variants.name === this.selectedNameVariant) {
        this.variantStock = variants.stock;
        if (variants.stock === 0) {
          this.variantOutOfStock = true;
        }
        this.productVariant = variants;
        this.selectedPrice = this.productVariant.price;
        const percent =
          (this.productDetails[0].product.price - this.selectedPrice) /
          this.productDetails[0].product.price;
        this.percent = Math.ceil(percent * 100);
        this.discountPercent = `-${Math.ceil(percent * 100)}%`;
      }
    });
  }

  selectedVariant(e: Event | any, index: number) {
    this.count = 1;
    this.quantityOutOfStock = false;
    this.variantArr.push(e);
    this.optionSelected.splice(index, 1, e);
    this.variantOutOfStock = false;
    this.findVariantPrice(this.optionSelected);
  }

  onSlideChange($event: any) {
    const currentSlideIndex = $event.startPosition;
    this.productDetails[0].product.image_data.forEach(
      (file: any, i: number) => {
        this.inview[i] = false; // Reset the inview status for all images
      }
    );

    this.inview[currentSlideIndex] = true;
  }

  inventory(product_code: string) {
    this.store.checkInventory(product_code).subscribe({
      next: (response: any) => {
        if (response.data === 'In stock') {
          this.available = true;
        } else {
          this.available = false;
        }
      },
      error: (err: any) => {},
    });
  }

  plus(product: any) {
    if (product?.variants?.length > 0) {
      this.productDetails[0].variants?.map((variants: any) => {
        if (variants.name === this.selectedNameVariant) {
          if (this.count === 1 && variants.stock === 0) {
            this.variantOutOfStock = true;
            this.snackBar.openSnackBar(
              'Sorry, this product is no longer in stock',
              'error-snackbar'
            );
          } else if (variants.stock > this.count) {
            this.count++;
          } else {
            this.quantityOutOfStock = true;
          }
        }
      });
    } else {
      if (this.count === 1 && product.quantity_in_stock === 0) {
        this.snackBar.openSnackBar(
          'Sorry, this product is no longer in stock',
          'error-snackbar'
        );
      } else if (product.product.quantity_in_stock > this.count) {
        this.count++;
      } else {
        this.quantityOutOfStock = true;
      }
    }
  }

  minus() {
    if (this.count !== 1) {
      this.count--;
      this.quantityOutOfStock = false;
    }
  }

  variantCart(
    product: any,
    qty: number,
    name: string,
    price: number,
    variantObj: any
  ) {
    this.store.addVariantToCart(product, qty, name, price, variantObj);
    this.count = 1;
  }

  variantBuyNow(product: any, name: string, price: number, variantObj: any) {
    this.store.addVariantToCart(product, 1, name, price, variantObj);
    this.router.navigate([`stores/${this.name.toLowerCase()}/cart`]);
  }

  getStore() {
    const name = this.name;
    this.store.findStoreByName(name).subscribe({
      next: (response: any) => {
        const storeResponse = response;
        const currentStore = {
          store_code: storeResponse.store_code,
          store_name: storeResponse.slug,
        };

        sessionStorage.setItem('currentStore', JSON.stringify(currentStore));

        this.store.checkStoreCart(storeResponse.store_code);

        this.getSingleProduct();
      },
      error: (err) => {
        this.snackBar.openSnackBar(`Store not found`, 'error-snackbar');
        this.productFetched = false;
      },
    });
  }

  getSingleProduct() {
    this.store.singleProduct(this.singleProduct.productCode).subscribe({
      next: (response: any) => {
        this.productDetails.push(response.data);

        if (this.productDetails[0].meta.length > 0) {
          this.arrangedMeta = this.productDetails[0].meta?.sort(
            (a: any, b: any) => {
              return this.order.indexOf(a.type) - this.order.indexOf(b.type);
            }
          );

          this.arrangedMeta.map((meta: any) =>
            this.optionSelected.push(meta.values[0])
          );

          this.findVariantPrice(this.optionSelected);
        }

        this.mainImage =
          this.productDetails[0]?.product.image_data[0]?.image_source;

        this.inview = Array.from(
          this.productDetails[0]?.product.image_data
        ).fill(false);

        this.inventory(this.productDetails[0].product.product_code);

        this.productFetched = false;
      },
      error: (err) => {
        this.productFetched = false;

        this.snackBar.openSnackBar(`An ${err.error.message}`, 'error-snackbar');
      },
    });
  }

  cart() {
    this.store.addCartItem(this.productDetails[0], this.count);
    // this.snackBar.openSnackBar('Product added to cart', 'success-snackbar');
    this.count = 1;
  }

  buyNow() {
    this.store.addCartItem(this.productDetails[0], 1);
    this.router.navigate([`stores/${this.name.toLowerCase()}/cart`]);
  }

  ngOnInit(): void {
    this.productFetched = true;
    const currentStore: any =
      JSON.parse(sessionStorage.getItem('currentStore')!) ?? null;

    this.singleProduct = {
      productCode: this.route.snapshot.params['id'],
    };

    this.singleStore = {
      storename: this.route.snapshot.params['name'],
    };

    this.name = this.singleStore.storename;

    if (
      currentStore &&
      (currentStore?.store_name).toLowerCase() !== this.name.toLowerCase()
    ) {
      this.store.cartItems = [];
      localStorage.removeItem(this.store.getStoreCartKey('cart'));
      localStorage.removeItem(this.store.getStoreCartKey('cartTotal'));
      this.getStore();
    } else if (currentStore) {
      this.getSingleProduct();
    } else {
      this.getStore();
    }

    this.link =
      environment.PRODUCT_URL +
      `/${this.singleStore?.storename}/${this.singleProduct.productCode}`;

    window.scrollTo(0, 0);
  }
}
