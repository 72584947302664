import { Location } from '@angular/common';
import {
  Component,
  OnInit,
  AfterContentChecked,
  ChangeDetectorRef,
  Input,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { StoreService } from 'src/app/shared/services/store.service';
// import { SingleStoreCheckoutModalComponent } from '../single-store-checkout-modal/single-store-checkout-modal.component';
import { environment } from 'src/environments/environment';
import { Router, ActivatedRoute } from '@angular/router';
import { SnackBarService } from 'src/app/shared/services/snackbar.service';
declare let ZestPayPop: any;
@Component({
  selector: 'app-single-store-cart',
  templateUrl: './single-store-cart.component.html',
  styleUrls: ['./single-store-cart.component.scss'],
})
export class SingleStoreCartComponent implements OnInit, AfterContentChecked {
  $this: any;
  constructor(
    public store: StoreService,
    private location: Location,
    public dialog: MatDialog,
    public router: Router,
    public snackBar: SnackBarService,
    private cdr: ChangeDetectorRef,
    private route: ActivatedRoute // private store: CartService,
  ) {
    // this.$this =
  }


  @Input() open: any;
  disable: boolean = false;
  pageLoad: boolean = false;
  cartItems: any[] = JSON.parse(
    localStorage.getItem(this.store.getStoreCartKey('cart'))!
  );
  storeName!: string;
  showShipping: boolean = false;
  deliveryFee: any;
  orderData: any;
  loading: any;
  shippingOption: string = '';
  regionOptions: any;
  orderObj: any;
  deliveryRates: any;
  orderCreated: any;
  ratesFetched: any;
  deliveryAmount: any;
  selectedCourier: any;
  stores: any;
  callbackResponse: any;
  publicKey: string = '';
  merchant: string = '';
  createStoreOrderError: string = '';
  currentStore: any;
  storeInfo: any;
  pickup: any;

  singleStore!: {
    storename: string;
  };

  add(a: number, b: number) {
    return a + b;
  }

  onBackClick() {
    this.location.back();
  }

  updateRadioState($event: any, rate: any) {
    const value = $event.target.value;
    this.selectedCourier = {
      parcel_id: rate.parcel_id,
      courier: rate.carrier,
      shipping_fee: rate.amount,
      rate_id: rate.rate_id,
      order_id: rate.order_id,
      delivery_time: rate.delivery_time,
    };

    this.deliveryAmount = +value;
  }

  plus(product: any, variant: string, price: number) {
    product.variants?.map((variantsObj: any) => {
      if (variantsObj.name === variant) {
        if (
          this.store.getVariantCount(variantsObj) === 0 &&
          variantsObj.stock === 0
        ) {
          this.snackBar.openSnackBar(
            'Sorry, this product is no longer in stock',
            'error-snackbar'
          );
        } else if (
          variantsObj.stock > this.store.getVariantCount(variantsObj)
        ) {
          this.store.addVariantToCart(product, 1, variant, price, variantsObj);
        } else {
          this.store.qunatityExceeded = true;
        }
      }
    });
  }

  minus(product: any, variantObj: any) {
    if (this.store.getVariantCount(variantObj) !== 0) {
      this.store.subtractVariantFromCart(product, variantObj);
    }
  }

  handleFormValuesData($event: any) {
    this.orderData = $event;

    if ($event) {
      if (this.orderData?.addressDTO?.region) {
        for (let location of this.regionOptions) {
          if (this.orderData?.addressDTO?.region === location.area) {
            this.deliveryFee = location.fee;
            this.deliveryAmount = this.deliveryFee;
          }
        }
        this.loading = true;
        this.store.createOrder(this.orderData).subscribe({
          next: (response: any) => {
            if (this.shippingOption === 'I_WILL_MANAGE_MY_OWN_SHIPPING') {
              this.loading = false;
              this.orderCreated = response.data.orderResponseDetailList;
              this.selectedCourier = {
                parcel_id: '',
                courier: '',
                shipping_fee: 0,
                rate_id: '',
                delivery_time: '',
                order_id: this.orderCreated.order.order_code,
              };
            }
          },
          error: (error: any) => {
            this.snackBar.openSnackBar(
              `${error.error.message}`,
              'error-snackbar'
            );
          },
        });
      } else {
        if (this.shippingOption === 'USE_ZEST_SHIPPING_PARTNERS') {
          this.ratesFetched = true;
          this.snackBar.openSnackBar(
            'Fetching shipping rates',
            'success-snackbar'
          );
          this.store.createOrder(this.orderData).subscribe({
            next: (response: any) => {
              this.deliveryRates = response.data.orderResponseZestPartnerList;
              this.ratesFetched = false;
              this.snackBar.openSnackBar(
                'Rates fetched successfully',
                'success-snackbar'
              );
            },
            error: (error: any) => {
              this.ratesFetched = false;
              this.snackBar.openSnackBar(
                `${error.error.message}`,
                'error-snackbar'
              );
            },
          });
        }
        if (this.shippingOption === 'PICKUP_IN_STORE') {
          this.store.createOrder(this.orderData).subscribe({
            next: (response: any) => {
              this.loading = false;

              this.pickup = response.data.orderResponseDetailList;
              this.deliveryAmount = 1;
              this.selectedCourier = {
                parcel_id: '',
                courier: '',
                shipping_fee: 0,
                rate_id: '',
                delivery_time: '',
                order_id: this.pickup.order.order_code,
              };
            },
            error: (error: any) => {
              this.loading = false;
              this.snackBar.openSnackBar(
                `${error.error.message}`,
                'error-snackbar'
              );
            },
          });
        }
      }
    }
  }

  createOrderObj() {
    return (this.orderObj = {
      order_details: this.cartItems?.map((cartItem: any) => ({
        product: { product_code: cartItem.product.product_code },
        variant_name: cartItem.variantPurchased
          ? cartItem.variantPurchased.name
          : null,
        product_quantity: cartItem.cartQuantity,
        unit_price: cartItem.variantPurchased
          ? cartItem.variantPurchased.price
          : cartItem.product.discounted_price
          ? cartItem.product.discounted_price
          : cartItem.product.price,
        subtotal:
          cartItem.cartQuantity *
          (cartItem.variantPurchased
            ? cartItem.variantPurchased.price
            : cartItem.product.discounted_price
            ? cartItem.product.discounted_price
            : cartItem.product.price),
      })),
      order: {
        total_price: this.total(),
        quantity: this.cartItems?.length,
      },
    });
  }

  updateShipppingInfo(ref: any) {
    this.store.createStoreOrder(this.selectedCourier, ref).subscribe({
      next: (response: any) => {
        this.postOrderAction();
      },
      error: (err: any) => {
        this.snackBar.openSnackBar(`${err.error.message}`, 'error-snackbar');
      },
    });
  }

  postOrderAction() {
    this.snackBar.openSnackBar(
      'payment successful, your order has been sent!',
      'success-snackbar'
    );
    window.location.assign(`/stores/${this.storeName}`);
    this.store.cartItems = [];
    localStorage.removeItem(this.store.getStoreCartKey('cart'));
    localStorage.removeItem(this.store.getStoreCartKey('cartTotal'));
  }

  getMerchantKey(merchant: string) {
    this.store.getAPIKEY(merchant).subscribe({
      next: (res: any) => {
        this.publicKey = res;
      },
      error: (err) => {},
    });
  }

  payWithZest() {
    let handler = ZestPayPop.setup({
      key: this.publicKey, // Replace with your public key
      email: this.orderData.addressDTO.email,
      amount: this.total() * 100,
      onClose: () => {},
      callback: (response: any) => {
        this.callbackResponse = response;

        this.updateShipppingInfo(this.callbackResponse.reference);
      },
    });
    handler.openIframe();
  }

  total() {
    if (this.deliveryAmount && this.shippingOption !== 'PICKUP_IN_STORE') {
      return this.store.reduceCartPrice() + this.deliveryAmount;
    } else return this.store.reduceCartPrice();
  }

  getStore(store: string) {
    this.store.findStoreByName(store).subscribe({
      next: (res: any) => {
        this.stores = res;

        this.pageLoad = true;

        this.shippingOption = this.stores.shipping_method;

        if (this.shippingOption === null) {
          this.storeInfo = {
            shipping: null,
            email: res.email,
            phone: res.phone_number,
          };
        } else if (this.shippingOption === 'PICKUP_IN_STORE') {
          this.storeInfo = {
            shipping: 'PICKUP_IN_STORE',
            email: res.email,
            phone: res.phone_number,
          };
        } else if (this.shippingOption === 'I_WILL_MANAGE_MY_OWN_SHIPPING') {
          this.store.getOwnShipping().subscribe({
            next: (res: any) => {
              this.regionOptions = res.data;
              if (this.regionOptions.length > 0) {
                this.storeInfo = null;
              } else {
                this.storeInfo = {
                  shipping: 'I_WILL_MANAGE_MY_OWN_SHIPPING',
                  email: this.stores.email,
                  phone: this.stores.phone_number,
                };
              }
            },
            error: (err: any) => {},
          });
        } else {
          this.storeInfo = null;
        }

        this.currentStore = {
          store_code: this.stores.store_code,
          store_name: this.stores.slug,
        };
        this.store.checkStoreCart(this.stores.store_code);

        sessionStorage.setItem(
          'currentStore',
          JSON.stringify(this.currentStore)
        );

        this.storeName = JSON.parse(
          sessionStorage.getItem('currentStore')!
        ).store_name.replace(/\s/g, '-');

        this.getMerchantKey(res.merchantId);
      },
      error: (err: any) => {
        if (err.error.status === 404)
          this.snackBar.openSnackBar(
            'Store does not exist, please check the link and try again.',
            'error-snackbar'
          );
        this.pageLoad = true;
        this.router.navigate(['/']);
      },
    });
  }

  getStoreNameFromSessionStorage(): void {
    const currenttStore = sessionStorage.getItem('currentStore');

    if (currenttStore) {
      const storeInfo = JSON.parse(currenttStore);
      this.storeName = storeInfo.store_name;
    } else {
    }
  }

  ngOnInit(): void {
    this.getStoreNameFromSessionStorage();
  }

  ngAfterContentChecked(): void {
    if (this.cartItems) {
      this.cartItems.length === 0
        ? localStorage.removeItem('cart')
        : this.cartItems;
    } else return;
    this.cartItems = this.store.getcartItems();
  }
}


