import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// import { CoreModule } from './modules/core/core.module';
import { environment } from '../environments/environment';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from './shared/shared.module';
import { HttpClientModule } from '@angular/common/http';
import { StarRatingModule } from 'angular-star-rating';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { ClientViewComponent } from './client-view/client-view.component';
import { SingleStoreHeaderComponent } from './single-store-header/single-store-header.component';
import { SingleStoreFooterComponent } from './single-store-footer/single-store-footer.component';
import { SingleStoreReviewsComponent } from './single-store-reviews/single-store-reviews.component';
import { SingleStoreProductListComponent } from './single-store-product-list/single-store-product-list.component';
import { SingleStoreSingleProductComponent } from './single-store-single-product/single-store-single-product.component';
import { DeliveryDetailComponent } from './cart-checkout/delivery-detail/delivery-detail.component';
// import { SingleStoreCartComponent } from './single-store-cart/single-store-cart.component';
import { SingleStoreCartComponent } from './cart-checkout/single-store-cart/single-store-cart.component';
import { SingleStoreCheckoutModalComponent } from './single-store-checkout-modal/single-store-checkout-modal.component';
import { ShareModule } from 'ngx-sharebuttons';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { SingleMobileCheckoutComponent } from './single-mobile-checkout/single-mobile-checkout.component';
import { StoreRatingsComponent } from './store-ratings/store-ratings.component';
import { CartCheckoutComponent } from './cart-checkout/cart-checkout.component';
import { CartCheckoutModule } from './cart-checkout/cart-checkout.module';
import { CartShippingComponent } from './cart-checkout/cart-shipping/cart-shipping.component';
import { SuccessComponent } from './success/success.component';
import { MatDialogModule } from '@angular/material/dialog';
import { SingleDetailsHeaderComponent } from './single-details-header/single-details-header.component';
import { CheckoutModule } from 'klump-angular';

@NgModule({
  declarations: [
    AppComponent,
    ClientViewComponent,
    SingleStoreHeaderComponent,
    SingleStoreFooterComponent,
    SingleStoreReviewsComponent,
    SingleStoreProductListComponent,
    SingleStoreSingleProductComponent,
    SingleStoreCartComponent,
    SingleStoreCheckoutModalComponent,
    SingleMobileCheckoutComponent,
    StoreRatingsComponent,
    CartCheckoutComponent,
    DeliveryDetailComponent,
    CartShippingComponent,
    SuccessComponent,
    SingleDetailsHeaderComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    StarRatingModule.forRoot(),
    SharedModule,
    ShareModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    CarouselModule,
    CartCheckoutModule,
    MatDialogModule,
    CheckoutModule,
  ],
  providers: [{ provide: MAT_DATE_LOCALE, useValue: 'en-GB' }],
  bootstrap: [AppComponent],
})
export class AppModule {}
