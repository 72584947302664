import { Location } from '@angular/common';
import { Component, OnInit, AfterContentChecked } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { SnackBarService } from '../shared/services/snackbar.service';
import { StoreService } from '../shared/services/store.service';
import { CustomValidator } from '../shared/ui-elements/validators/custom-validator';
import { SingleStoreCheckoutModalComponent } from '../single-store-checkout-modal/single-store-checkout-modal.component';
import { environment } from 'src/environments/environment';
declare let ZestPayPop: any;

@Component({
  selector: 'app-cart-checkout',
  templateUrl: './cart-checkout.component.html',
  styleUrls: ['./cart-checkout.component.scss'],
})
export class CartCheckoutComponent implements OnInit, AfterContentChecked {
  constructor(
    public store: StoreService,
    public router: Router,
    private snackBar: SnackBarService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    public location: Location
  ) {
    this.multiStageForm = this.fb.group({
      addressDTO: this.fb.group({
        last_name: new FormControl('', [
          Validators.required,
          Validators.pattern("^[a-zA-Z-' ]+$"),
        ]),
        first_name: new FormControl('', [
          Validators.required,
          Validators.pattern("^[a-zA-Z-' ]+$"),
        ]),
        email: new FormControl('', [
          Validators.required,
          Validators.email,
          Validators.pattern(
            '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,4}$'
          ),
        ]),
        phone: new FormControl('', [
          Validators.required,
          Validators.minLength(10),
          Validators.maxLength(10),
        ]),
        country: new FormControl('NG', Validators.required),
        state: new FormControl('Select a state', Validators.required),
        city: new FormControl('', Validators.required),
        line1: new FormControl('', Validators.required),
        metadata: this.fb.group({
          delivery_note: new FormControl(''),
        }),
      }),
    });
  }

  disable: boolean = false;
  pageLoad: boolean = false;
  cartItems: any[] = [];
  storeName!: string;
  showShipping: boolean = false;
  deliveryFee: any;
  orderData: any;
  orderId: string | undefined;
  loading: any;
  shippingOption: string = '';
  createPickupOrder: boolean = false;
  shippingMethods: string[] = [];
  regionOptions: any;
  orderObj: any;
  deliveryRates: any;
  orderCreated: any;
  ratesFetched: any;
  deliveryAmount: any;
  selectedCourier: any;
  stores: any;
  callbackResponse: any;
  publicKey: string = '';
  merchant: string = '';
  createStoreOrderError: string = '';
  currentStore: any;
  storeInfo: any;
  pickup: any;
  multiStageForm: FormGroup;
  availableShipping: string[] = [];
  receivedValue: string = '';
  orderDetailValue: any;
  isFormValid!: boolean;
  klumpHide: boolean = true;

  singleStore!: {
    storename: string;
  };

  tabs = ['Cart', 'Delivery detail', 'Shipping', 'Summary'];

  openTab = this.tabs.indexOf('Cart');
  toggleTabs($tabNumber: number, tab?: string) {
    this.openTab = $tabNumber;
  }

  onBackClick() {
    this.location.back();
  }

  plus(product: any, variant: string, price: number) {
    product.variants?.map((variantsObj: any) => {
      if (variantsObj.name === variant) {
        if (
          this.store.getVariantCount(variantsObj) === 0 &&
          variantsObj.stock === 0
        ) {
          this.snackBar.openSnackBar(
            'Sorry, this product is no longer in stock',
            'error-snackbar'
          );
        } else if (
          variantsObj.stock > this.store.getVariantCount(variantsObj)
        ) {
          this.store.addVariantToCart(product, 1, variant, price, variantsObj);
        } else {
          this.snackBar.openSnackBar(
            'Sorry, your order exceeds the stock currently available',
            'error-snackbar'
          );
        }
      }
    });
  }

  minus(product: any, variantObj: any) {
    if (this.store.getVariantCount(variantObj) !== 0) {
      this.store.subtractVariantFromCart(product, variantObj);
    }
  }

  handleDeliveryCharge(delivery: string) {
    this.deliveryAmount = delivery;
  }

  handleFormValuesData($event: any) {
    this.orderData = $event;

    if ($event) {
      if (this.orderData?.addressDTO?.region) {
        for (let location of this.regionOptions) {
          if (this.orderData?.addressDTO?.region === location.area) {
            this.deliveryFee = location.fee;
            this.deliveryAmount = this.deliveryFee;
          }
        }
        this.loading = true;
        this.store.createOrder(this.orderData).subscribe({
          next: (response: any) => {
            if (this.shippingOption === 'I_WILL_MANAGE_MY_OWN_SHIPPING') {
              this.loading = false;
              this.orderCreated = response.data.orderResponseDetailList;

              this.selectedCourier = {
                parcel_id: '',
                courier: '',
                shipping_fee: 0,
                rate_id: '',
                delivery_time: '',
                order_id: this.orderCreated.order.order_code,
                eta: '',
              };
            }
          },
          error: (error: any) => {
            this.snackBar.openSnackBar(
              `${error.error.message}`,
              'error-snackbar'
            );
          },
        });
      } else {
        if (this.shippingOption === 'USE_ZEST_SHIPPING_PARTNERS') {
          this.ratesFetched = true;
          this.snackBar.openSnackBar(
            'Fetching shipping rates',
            'success-snackbar'
          );
          this.store.createOrder(this.orderData).subscribe({
            next: (response: any) => {
              this.deliveryRates = response.data.orderResponseZestPartnerList;
              this.ratesFetched = false;
              this.snackBar.openSnackBar(
                'Rates fetched successfully',
                'success-snackbar'
              );
            },
            error: (error: any) => {
              this.ratesFetched = false;
              this.snackBar.openSnackBar(
                `${error.error.message}`,
                'error-snackbar'
              );
            },
          });
        }
        if (this.shippingOption === 'PICKUP_IN_STORE') {
          this.store.createOrder(this.orderData).subscribe({
            next: (response: any) => {
              this.loading = false;

              this.pickup = response.data.orderResponseDetailList;

              this.deliveryAmount = 1;
              this.selectedCourier = {
                parcel_id: '',
                courier: '',
                shipping_fee: 0,
                rate_id: '',
                delivery_time: '',
                order_id: this.pickup.order.order_code,
                eta: '',
              };
            },
            error: (error: any) => {
              this.loading = false;
              this.snackBar.openSnackBar(
                `${error.error.message}`,
                'error-snackbar'
              );
            },
          });
        }
      }
    }
  }

  checkoutModal() {
    const dialogRef = this.dialog.open(SingleStoreCheckoutModalComponent, {
      width: '500px',
      height: '90%',
      panelClass: 'custom-dialog-container',
      backdropClass: 'backdropBackground',
      data: {
        region: this.regionOptions,
        order: this.createOrderObj(),
        store: this.storeInfo,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.orderData = result?.orderDetail;

      if (result) {
        if (this.orderData?.addressDTO?.region) {
          for (let location of this.regionOptions) {
            if (this.orderData?.addressDTO?.region === location.area) {
              this.deliveryFee = location.fee;
              this.deliveryAmount = this.deliveryFee;
            }
          }
          this.loading = true;
          this.store.createOrder(this.orderData).subscribe({
            next: (response: any) => {
              this.loading = false;
              this.orderCreated = response.data.orderResponseDetailList;

              this.selectedCourier = {
                parcel_id: '',
                courier: '',
                shipping_fee: 0,
                rate_id: '',
                delivery_time: '',
                order_id: this.orderCreated.order.order_code,
                eta: '',
              };
            },
            error: (error: any) => {
              this.snackBar.openSnackBar(
                `${error.error.message}`,
                'error-snackbar'
              );
            },
          });
        } else {
          if (this.shippingOption === 'USE_ZEST_SHIPPING_PARTNERS') {
            this.ratesFetched = true;
            this.snackBar.openSnackBar(
              'Fetching shipping rates',
              'success-snackbar'
            );
            this.store.createOrder(this.orderData).subscribe({
              next: (response: any) => {
                this.deliveryRates = response.data.orderResponseZestPartnerList;
                this.ratesFetched = false;
                this.snackBar.openSnackBar(
                  'Rates fetched successfully',
                  'success-snackbar'
                );
              },
              error: (error: any) => {
                this.ratesFetched = false;
                this.snackBar.openSnackBar(
                  `${error.error.message}`,
                  'error-snackbar'
                );
              },
            });
          }
          if (this.shippingOption === 'PICKUP_IN_STORE') {
            this.store.createOrder(this.orderData).subscribe({
              next: (response: any) => {
                this.loading = false;

                this.pickup = response.data.orderResponseDetailList;
                this.deliveryAmount = 1;
                this.selectedCourier = {
                  parcel_id: '',
                  courier: '',
                  shipping_fee: 0,
                  rate_id: '',
                  delivery_time: '',
                  order_id: this.pickup.order.order_code,
                  eta: '',
                };
              },
              error: (error: any) => {
                this.loading = false;
                this.snackBar.openSnackBar(
                  `${error.error.message}`,
                  'error-snackbar'
                );
              },
            });
          }
        }
      }
    });
  }

  handleOrderValue(orderDetails: any) {
    this.orderDetailValue = orderDetails;
  }

  createOrderObj() {
    return (this.orderObj = {
      order_details: this.cartItems?.map((cartItem: any) => ({
        product: { product_code: cartItem.product.product_code },
        variant_name: cartItem.variantPurchased
          ? cartItem.variantPurchased.name
          : null,
        product_quantity: cartItem.cartQuantity,
        unit_price: cartItem.variantPurchased
          ? cartItem.variantPurchased.price
          : cartItem.product.discounted_price
          ? cartItem.product.discounted_price
          : cartItem.product.price,
        subtotal:
          cartItem.cartQuantity *
          (cartItem.variantPurchased
            ? cartItem.variantPurchased.price
            : cartItem.product.discounted_price
            ? cartItem.product.discounted_price
            : cartItem.product.price),
      })),
      order: {
        total_price: this.total(),
        quantity: this.cartItems?.length,
      },
    });
  }

  handleSelectedShipPartner(ship: any) {
    if (this.receivedValue === 'I_WILL_MANAGE_MY_OWN_SHIPPING') {
      this.deliveryFee = ship;
      return;
    }

    this.deliveryFee = ship.amount;
    this.selectedCourier = {
      redis_key: null || '',
      courier: ship.carrier,
      shipping_fee: ship.amount,
      rate_id: ship.rate_id,
      order_id: ship.order_id,
      delivery_time: ship.delivery_time,
      pickup_time: ship.pickup_time,
      eta: ship.eta,
      parcel_id: ship.parcel_id,
    };
  }

  handleFormValid(valid: any) {
    this.orderData = { ...this.createOrderObj(), ...this.multiStageForm.value };

    if (this.multiStageForm.invalid) {
      CustomValidator.validateAllFormFields(this.multiStageForm);
      return;
    }

    if (this.receivedValue !== 'PICKUP_IN_STORE') {
      this.snackBar.openSnackBar('Fetching shipping rates', 'success-snackbar');
      this.store.createOrder(this.orderData).subscribe({
        next: (response: any) => {
          this.deliveryRates = response.data.orderResponseZestPartnerList;

          this.store.fetchingRates = false;
          this.ratesFetched = false;
          this.snackBar.openSnackBar(
            'Rates fetched successfully',
            'success-snackbar'
          );
        },
        error: (error: any) => {
          this.store.fetchingRates = false;
          this.snackBar.openSnackBar(
            `${error.error.message}`,
            'error-snackbar'
          );
        },
      });
    }
  }

  klump() {
    this.pay();
  }

  // handleFormValid(valid: any) {
  //   this.orderData = { ...this.createOrderObj(), ...this.multiStageForm.value };

  //   if (this.multiStageForm.valid) {
  //     this.snackBar.openSnackBar('Fetching shipping rates', 'success-snackbar');
  //     this.store.createOrder(this.orderData).subscribe({
  //       next: (response: any) => {
  //         this.deliveryRates = response.data.orderResponseZestPartnerList;

  //         this.store.fetchingRates = false;
  //         this.ratesFetched = false;
  //         this.snackBar.openSnackBar(
  //           'Rates fetched successfully',
  //           'success-snackbar'
  //         );
  //       },
  //       error: (error: any) => {
  //         this.store.fetchingRates = false;
  //         this.snackBar.openSnackBar(
  //           `${error.error.message}`,
  //           'error-snackbar'
  //         );
  //       },
  //     });
  //   }
  // }

  placePickupOrder() {
    this.createPickupOrder = true;
    let getControl = this.multiStageForm.get('addressDTO') as FormGroup;

    this.orderData = { ...this.createOrderObj(), ...this.multiStageForm.value };
    if (this.receivedValue === 'PICKUP_IN_STORE') {
      getControl?.get('country')?.clearValidators();
      getControl?.get('state')?.clearValidators();
      getControl?.get('city')?.clearValidators();
      getControl?.get('line1')?.clearValidators();
      getControl?.get('region')?.clearValidators();

      getControl?.get('country')?.updateValueAndValidity();
      getControl?.get('state')?.updateValueAndValidity();
      getControl?.get('city')?.updateValueAndValidity();
      getControl?.get('line1')?.updateValueAndValidity();
      getControl?.get('region')?.updateValueAndValidity();
    }

    if (this.multiStageForm.invalid) {
      CustomValidator.validateAllFormFields(this.multiStageForm);
      return;
    }

    if (this.receivedValue === 'PICKUP_IN_STORE') {
      this.orderData.addressDTO.metadata.delivery_fee = 0;
      this.orderData.addressDTO.metadata.shipping_method = 'Pickup in store';
      this.deliveryAmount = 0;
      this.store.createOrder(this.orderData).subscribe({
        next: async (response: any) => {
          this.createPickupOrder = false;

          this.pickup = response.data.orderResponseDetailList;

          // this.deliveryAmount = 1;
          this.selectedCourier = {
            redis_key: '',
            courier: '',
            shipping_fee: 0,
            rate_id: '',
            delivery_time: '',
            pickup_time: '',
            order_id: this.pickup.order.order_code,
            eta: '',
            parcel_id: '',
          };
          await this.payWithZest();
        },
        error: (error: any) => {
          this.loading = false;
          this.snackBar.openSnackBar(
            `${error.error.message}`,
            'error-snackbar'
          );
        },
      });
    }
  }

  handleShipping($event: string) {
    this.receivedValue = $event;
  }

  updateShipppingInfo(ref: string) {
    this.store.createStoreOrder(this.selectedCourier, ref).subscribe({
      next: (response: any) => {
        this.postOrderAction();
      },
      error: (err: any) => {
        this.snackBar.openSnackBar(`${err.error.message}`, 'error-snackbar');
      },
    });
  }

  postOrderAction() {
    window.location.assign(`/stores/${this.storeName}/payment-success`);
    this.store.cartItems = [];
    localStorage.removeItem(this.store.getStoreCartKey('cart'));
    localStorage.removeItem(this.store.getStoreCartKey('cartTotal'));
  }

  getMerchantKey(merchant: string) {
    this.store.getAPIKEY(merchant).subscribe({
      next: (res: any) => {
        this.publicKey = res;
      },
      error: (err) => {},
    });
  }

  placeShipOrder() {
    this.createPickupOrder = true;
    this.orderData = { ...this.createOrderObj(), ...this.multiStageForm.value };

    if (this.orderData) {
      if (this.orderData?.addressDTO?.region) {
        // for (let location of this.regionOptions) {
        //   if (this.orderData?.addressDTO?.region === location.area) {
        //     this.deliveryFee = location.fee;
        //     this.deliveryAmount = this.deliveryFee;
        //   }
        // }
        this.loading = true;
        this.store.createOrder(this.orderData).subscribe({
          next: (response: any) => {
            this.createPickupOrder = false;
            this.orderCreated = response.data.orderResponseDetailList;
            this.selectedCourier = {
              redis_key: '',
              courier: '',
              shipping_fee: 0,
              rate_id: '',
              delivery_time: '',
              pickup_time: '',
              order_id: this.orderCreated.order.order_code,
              eta: '',
              parcel_id: '',
            };
            this.toggleTabs(3);
          },
          error: (error: any) => {
            this.snackBar.openSnackBar(
              `${error.error.message}`,
              'error-snackbar'
            );
          },
        });
      }
      if (this.shippingMethods.includes('USE_ZEST_SHIPPING_PARTNERS')) {
        this.toggleTabs(3);
      }
    }
  }

  payWithZest() {
    let handler = ZestPayPop.setup({
      key: this.publicKey, // Replace with your public key
      email: this.multiStageForm.value.addressDTO.email,
      amount: this.total() * 100,
      metadata: {
        source: 'Storefront',
      },
      onClose: () => {},
      callback: (response: any) => {
        this.callbackResponse = response;

        this.updateShipppingInfo(this.callbackResponse.reference);
      },
    });

    handler.openIframe();
  }

  klumpPublicKey = environment.KLUMP_API;

  onSuccess(data: any) {
    const klumpref = data?.data?.data?.data?.reference;

    const transactionDetails = {
      amount: this.total(),
      orderCode: this.orderId || this.selectedCourier.order_id,
      paymentChannel: 'WEB',
      paymentType: 'KLUMP_BNPL',
      tranRef: klumpref,
      source: 'Marketplace',
      shippingMethod: 'Use Zest Shipping Method',
    };

    this.store.verifyTransaction(transactionDetails).subscribe({
      next: (verificationData: any) => {
        const transRef = verificationData.data.tranRef;
        this.updateShipppingInfo(transRef);
      },
      error: (error) => {},
    });
  }

  onError(data: { message: string; type: string }) {
    // Handle the error response here
  }

  onLoad(data: { status: boolean; type: string }) {}

  onOpen(data: { status: boolean; type: string }) {}

  onClose(data: { status: boolean; type: string }) {}

  placeOrderAndPay() {
    this.loading = true;
    let getControl = this.multiStageForm.get('addressDTO') as FormGroup;

    // Create the order data from the form
    this.orderData = { ...this.createOrderObj(), ...this.multiStageForm.value };
    if (this.receivedValue === 'PICKUP_IN_STORE') {
      getControl?.get('country')?.clearValidators();
      getControl?.get('state')?.clearValidators();
      getControl?.get('city')?.clearValidators();
      getControl?.get('line1')?.clearValidators();
      getControl?.get('region')?.clearValidators();

      getControl?.get('country')?.updateValueAndValidity();
      getControl?.get('state')?.updateValueAndValidity();
      getControl?.get('city')?.updateValueAndValidity();
      getControl?.get('line1')?.updateValueAndValidity();
      getControl?.get('region')?.updateValueAndValidity();
    }

    // Validate the form
    if (this.multiStageForm.invalid) {
      CustomValidator.validateAllFormFields(this.multiStageForm);
      this.loading = false; // Stop loading if the form is invalid
      return;
    }

    if (this.receivedValue === 'PICKUP_IN_STORE') {
      this.orderData.addressDTO.metadata.delivery_fee = 0;
      this.orderData.addressDTO.metadata.shipping_method = 'Pickup in store';
      this.deliveryAmount = 0;
      this.store.createOrder(this.orderData).subscribe({
        next: async (response: any) => {
          const createdOrder = response.data.orderResponseDetailList;
          this.orderId = createdOrder?.order?.order_code;
          this.selectedCourier = {
            redis_key: '',
            courier: '',
            shipping_fee: 0,
            rate_id: '',
            delivery_time: '',
            pickup_time: '',
            order_id: createdOrder?.order?.order_code,
            eta: '',
            parcel_id: '',
          };
          await this.pay();
        },
        error: (error: any) => {
          this.loading = false;
        },
      });
    }
  }

  pay() {
    const cartItems =
      JSON.parse(localStorage.getItem(this.store.getStoreCartKey('cart'))!) ||
      [];
    if (this.multiStageForm.invalid) {
      this.snackBar.openSnackBar(
        'Please complete the form before proceeding',
        'error-snackbar'
      );
      return;
    }

    const klumpData = {
      amount: this.total(),
      shipping_fee: parseFloat(this?.selectedCourier?.shipping_fee) || 0,
      currency: 'NGN',
      redirect_url: cartItems.length ? cartItems[0].product.store.url : '',
      merchant_reference: cartItems.length
        ? cartItems[0].product.store.merchant_code ||
          cartItems[0].product.store.merchantId
        : '',

      meta_data: {
        customer: this.multiStageForm.value.addressDTO.first_name,
        email: this.multiStageForm.value.addressDTO.email,
      },
      items: cartItems.map((item: any) => ({
        image_url:
          item.product?.image_data && item.product.image_data.length > 0
            ? encodeURI(item.product.image_data[0].image_source)
            : '',
        item_url:
          item.product?.store?.url + '/' + item.product?.product_code || '',
        name: item.product?.product_name || '',
        unit_price:
          item?.purchasedPrice ||
          item.product?.discounted_price ||
          item.product?.price,
        quantity: item.cartQuantity,
      })),
    };

    // @ts-ignore (if necessary)
    new Klump({
      publicKey: this.klumpPublicKey,
      data: klumpData,
      onSuccess: this.onSuccess.bind(this),
      onError: this.onError.bind(this),
      onLoad: this.onLoad.bind(this),
      onOpen: this.onOpen.bind(this),
      onClose: this.onClose.bind(this),
    });
  }

  total() {
    if (this.deliveryAmount && this.shippingOption !== 'PICKUP_IN_STORE') {
      return this.store.reduceCartPrice() + this.deliveryAmount;
    } else return this.store.reduceCartPrice();
  }

  getStore(store: string) {
    this.store.findStoreByName(store).subscribe({
      next: (res: any) => {
        this.stores = res;

        this.pageLoad = true;

        // this.shippingOption = this.stores.shippingMethods;
        this.shippingMethods = this.stores.shippingMethods;

        if (this.shippingMethods.length === 0) {
          this.storeInfo = {
            shipping: null,
            email: res.email,
            phone: res.phone_number,
          };
        } else if (
          this.shippingMethods.includes('I_WILL_MANAGE_MY_OWN_SHIPPING')
        ) {
          let newControl = this.multiStageForm.get('addressDTO') as FormGroup;
          newControl?.addControl(
            'region',
            new FormControl('', Validators.required)
          );
          this.store.getOwnShipping().subscribe({
            next: (res: any) => {
              this.regionOptions = res.data;
              if (this.regionOptions.length > 0) {
                this.storeInfo = null;
              } else {
                this.storeInfo = {
                  shipping: 'I_WILL_MANAGE_MY_OWN_SHIPPING',
                  email: this.stores.email,
                  phone: this.stores.phone_number,
                };
              }
            },
            error: (err: any) => {},
          });
        } else if (
          (this.shippingMethods.length === 1 &&
            this.shippingMethods.includes('PICKUP_IN_STORE')) ||
          this.shippingMethods.includes('PICKUP_IN_STORE')
        ) {
          this.storeInfo = {
            shipping: 'PICKUP_IN_STORE',
            email: res.email,
            phone: res.phone_number,
          };
        } else {
          this.storeInfo = null;
        }

        this.currentStore = {
          store_code: this.stores.store_code,
          store_name: this.stores.slug,
        };
        this.store.checkStoreCart(this.stores.store_code);

        sessionStorage.setItem(
          'currentStore',
          JSON.stringify(this.currentStore)
        );

        this.storeName = JSON.parse(
          sessionStorage.getItem('currentStore')!
        ).store_name;

        this.getMerchantKey(res.merchantId);
      },
      error: (err: any) => {
        if (err.error.status === 404)
          this.snackBar.openSnackBar(
            'Store does not exist, please check the link and try again.',
            'error-snackbar'
          );
        this.pageLoad = true;
        this.router.navigate(['/']);
      },
    });
  }

  ngOnInit(): void {
    this.singleStore = {
      storename: this.route.snapshot.params['name'],
    };
    const name = this.singleStore.storename;
    const currentStore: any =
      JSON.parse(sessionStorage.getItem('currentStore')!) ?? null;

    if (
      currentStore &&
      (currentStore?.store_name).toLowerCase() !== name.toLowerCase()
    ) {
      this.store.cartItems = [];
      localStorage.removeItem(this.store.getStoreCartKey('cart'));
      localStorage.removeItem(this.store.getStoreCartKey('cartTotal'));
      this.getStore(name);
    } else {
      this.getStore(name);
    }

    this.pageLoad = false;
    window.scrollTo(0, 0);
  }

  ngAfterContentChecked(): void {
    this.cartItems = JSON.parse(
      localStorage.getItem(this.store.getStoreCartKey('cart'))!
    );

    if (this.receivedValue === 'PICKUP_IN_STORE') {
      this.tabs = ['Cart', 'Delivery detail'];
    } else {
      this.tabs = ['Cart', 'Delivery detail', 'Shipping', 'Summary'];
    }

    if (this.orderData?.addressDTO?.region) {
      for (let location of this.regionOptions) {
        if (this.orderData?.addressDTO?.region === location.area) {
          this.deliveryFee = location.fee;
          this.deliveryAmount = this.deliveryFee;
        }
      }
    }

    if (this.deliveryFee) {
      this.deliveryAmount = +this.deliveryFee;
    }
  }
}
