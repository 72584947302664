import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { SnackBarService } from './snackbar.service';

@Injectable({
  providedIn: 'root',
})
export class StoreService {
  constructor(
    private router: Router,
    private https: HttpClient,
    private snackBar: SnackBarService
  ) {}

  private products: any[] = JSON.parse(localStorage.getItem('products')!)
    ? JSON.parse(localStorage.getItem('products')!)
    : [];

  private baseUrl: string = `${environment.BASE_URL}/storefront/api/v1`;
  private showCart = false;
  private count = Array.from(this.products).fill(1);
  private cartCount = 0;
  private cartTotal = 0;
  public cartItems: any[] =
    JSON.parse(localStorage.getItem(this.getStoreCartKey('cart'))!) || [];
  private discount = [];
  private delivered = false;
  public showMobile = false;
  public pageCreation!: boolean;
  public outOfStock: boolean = false;
  public orderCreated: boolean = false;
  public qunatityExceeded: boolean = false;
  public fetchingRates: boolean = false;
  public currentPage: number = 1;
  public getStoreCartKey(item: string): string {
    const currentStore = JSON.parse(sessionStorage.getItem('currentStore')!);
    const storeName = currentStore
      ? currentStore.store_name.replace(/\s/g, '-')
      : 'default';
    return `${storeName}-${item}`;
  }

  backgroundColor: any;

  isCartShown() {
    return this.showCart;
  }

  getDiscount() {
    if (JSON.parse(localStorage.getItem('discounts')!)) {
      return true;
    } else return false;
  }

  isMobileShown() {
    return this.showMobile;
  }

  showMobileCheckout() {
    this.showMobile = !this.showMobile;
  }

  cartPlus(id: string) {
    const cartProduct = this.products.find((item) => item.id === id);
    this.addCartItem(cartProduct);
  }

  cartMinus(id: string) {
    const cartProduct = this.products.find((item) => item.id === id);
    this.subtractCartItem(cartProduct);
  }

  getCartNumber() {
    return this.cartCount;
  }

  showCartPage() {
    this.showCart = !this.showCart;
  }

  checkStoreCart(code: string) {
    if (
      this.cartItems.length > 0 &&
      this.cartItems[0]?.product?.store.store_code !== code
    ) {
      localStorage.removeItem(this.getStoreCartKey('cart'));
      localStorage.removeItem(this.getStoreCartKey('cartTotal'));
      this.cartItems = [];
    }
  }

  findStoreByName(name: string) {
    return this.https.get(`${this.baseUrl}/store/find/${name}`);
  }

  getAPIKEY(id: string) {
    return this.https.get(
      `${environment.BASE_URL}/storefront/auth/get/api-key/merchant/${id}`,
      { responseType: 'text' }
    );
  }

  // getPicture(id: string) {
  //   return this.https.get(
  //     `${this.baseUrl}/store/get-profile-picture?merchantId=${id}`
  //   );
  // }

  getPicture(name: string) {
    return this.https.get(`${this.baseUrl}/store/find/${name}`);
  }

  isProductCreated() {
    const currentStore: any = JSON.parse(
      sessionStorage.getItem('currentStore')!
    );

    return this.https.get(
      `${this.baseUrl}/store/${currentStore.store_code}/filter/date-added/asc`
    );
  }

  submitRating(cusCode: string, strCode: string, token: string, data: any) {
    return this.https.post(
      `${this.baseUrl}/review/add?customer_code=${cusCode}&store_code=${strCode}&token=${token}`,
      data
    );
  }

  singleProduct(productCode: string) {
    const currentStore = JSON.parse(
      sessionStorage.getItem('currentStore')!
    ).store_code;
    return this.https.get(
      `${this.baseUrl}/store/${currentStore}/find/product/${productCode}`
    );
  }

  getProductOnClient(storeCode: string, pageNumber: number) {
    return this.https.get(
      `${this.baseUrl}/store/${storeCode}/filter/date-added/desc?pageNo=${pageNumber}&pageSize=24` //what does page size mean and page number
    );
  }

  productSearchAndFilter(name: string, code: string, pageNumber: number) {
    return this.https.get(
      `${this.baseUrl}/store/${code}/search?query=${name}&pageNo=${pageNumber}&pageSize=24`
    );
  }

  productFilterByPrice(type: string, pageNumber: number) {
    const currentStore = JSON.parse(
      sessionStorage.getItem('currentStore')!
    ).store_code;
    return this.https.get(
      `${this.baseUrl}/store/${currentStore}/filter/price/${type}?pageNo=${pageNumber}&pageSize=24&query=`
    );
  }

  createOrder(order: any) {
    const currentStore: any = JSON.parse(
      sessionStorage.getItem('currentStore')!
    );
    return this.https.post(
      `${this.baseUrl}/customer/order/create/str-shipping?store_code=${currentStore.store_code}`,
      order
    );
  }

  createStoreOrder(courierInfo: any, trx: any) {
    return this.https.post(
      `${this.baseUrl}/order/${courierInfo.order_id}/store/create?parcel_id=${courierInfo.parcel_id}&tran_id=${trx}&redis_key=${courierInfo.redis_key}&courier=${courierInfo.courier}&shipping_fee=${courierInfo.shipping_fee}&rate_id=${courierInfo.rate_id}&delivery_time=${courierInfo.delivery_time}&eta=${courierInfo.eta}`,
      {}
    );
  }

  getOwnShipping() {
    const currentStore: any = JSON.parse(
      sessionStorage.getItem('currentStore')!
    );
    return this.https.get(
      `${this.baseUrl}/get/${currentStore.store_code}/shipping/all`
    );
  }

  checkInventory(code: string) {
    return this.https.get(
      `${this.baseUrl}/stock/check-status?product_code=${code}`
    );
  }

  getCartLenght() {
    return this.cartItems.length;
  }

  addVariantToCart(
    product: any,
    quantity: number,
    variant?: any,
    purchasedPrice?: any,
    variantObj?: any
  ) {
    const storeName = JSON.parse(sessionStorage.getItem('currentStore')!)
      ? JSON.parse(sessionStorage.getItem('currentStore')!).store_name.replace(
          /\s/g,
          '-'
        )
      : null;
    const existingCartItem = this.cartItems.find(
      (item: any) =>
        item.variantPurchased?.product_variant_code ===
        variantObj?.product_variant_code
    );

    if (existingCartItem) {
      if (variantObj?.stock <= existingCartItem.cartQuantity) {
        this.snackBar.openSnackBar(
          'Sorry, you cannot add more of this product to cart',
          'error-snackbar'
        );
        return;
      }

      if (existingCartItem.cartQuantity + quantity > variantObj?.stock) {
        this.qunatityExceeded = true;
        this.snackBar.openSnackBar(
          `Sorry, you cannot add more than ${
            variantObj?.stock - existingCartItem.cartQuantity
          } of this product to cart`,
          'error-snackbar'
        );
        return;
      }

      this.cartItems = this.cartItems.map((cartItem) =>
        cartItem.variantPurchased?.product_variant_code ===
        variantObj?.product_variant_code
          ? { ...cartItem, cartQuantity: cartItem.cartQuantity + quantity } // Change this logic to accept quantity sent instead of hard coded value 1
          : cartItem
      );
      if (this.router.url !== `/stores/${storeName.toLowerCase()}/cart`) {
        this.snackBar.openSnackBar('Product added to cart', 'success-snackbar');
      }

      localStorage.setItem(
        this.getStoreCartKey('cart'),
        JSON.stringify(this.cartItems)
      );
      return this.cartItems;
    } else {
      if (quantity > variantObj?.stock) {
        this.snackBar.openSnackBar(
          `Sorry, this product is no longer in stock`,
          'error-snackbar'
        );
        return;
      }
      this.cartItems = [
        ...this.cartItems,
        {
          ...product,
          cartQuantity: quantity,
          purchasedvariant: variant,
          purchasedPrice: purchasedPrice,
          variantPurchased: variantObj,
        },
      ];
      if (this.router.url !== `/stores/${storeName.toLowerCase()}/cart`) {
        this.snackBar.openSnackBar('Product added to cart', 'success-snackbar');
      }
      localStorage.setItem(
        this.getStoreCartKey('cart'),
        JSON.stringify(this.cartItems)
      );
      return this.cartItems;
    }
  }

  subtractVariantFromCart(product: any, variantObj?: any) {
    const storeName = JSON.parse(sessionStorage.getItem('currentStore')!)
      ? JSON.parse(sessionStorage.getItem('currentStore')!).store_name.replace(
          /\s/g,
          '-'
        )
      : null;
    const existingCartItem = this.cartItems.find(
      (item: any) =>
        item.variantPurchased?.product_variant_code ===
        variantObj?.product_variant_code
    );
    if (existingCartItem.cartQuantity === 1) {
      if (this.router.url === `/stores/${storeName.toLowerCase()}/cart`) {
        this.removeVariantCartItem(variantObj);
        return;
      } else {
        existingCartItem.cartQuantity = 0;
        this.cartItems = this.cartItems.filter(
          (cartItem) =>
            cartItem.variantPurchased?.product_variant_code !==
            variantObj?.product_variant_code
        );

        localStorage.setItem(
          this.getStoreCartKey('cart'),
          JSON.stringify(this.cartItems)
        );
        return this.cartItems;
      }
    }

    if (existingCartItem) {
      this.qunatityExceeded = false;
      this.cartItems = this.cartItems.map((cartItem) =>
        cartItem.variantPurchased?.product_variant_code ===
        variantObj?.product_variant_code
          ? { ...cartItem, cartQuantity: cartItem.cartQuantity - 1 }
          : cartItem
      );
      localStorage.setItem(
        this.getStoreCartKey('cart'),
        JSON.stringify(this.cartItems)
      );
      return this.cartItems;
    }
    return;
  }

  addCartItem(product: any, quantity?: number) {
    const storeName = JSON.parse(sessionStorage.getItem('currentStore')!)
      ? JSON.parse(sessionStorage.getItem('currentStore')!).store_name
      : null;
    const existingCartItem = this.cartItems.find(
      (item: any) => item.product.product_code === product.product.product_code
    );
    if (existingCartItem) {
      if (
        existingCartItem.product.quantity_in_stock <=
          existingCartItem.cartQuantity ||
        (quantity &&
          existingCartItem.cartQuantity + quantity >
            existingCartItem.product.quantity_in_stock)
      ) {
        this.qunatityExceeded = true;

        return;
      }
      this.cartItems = this.cartItems.map((cartItem) =>
        cartItem.product.product_code === product.product.product_code
          ? {
              ...cartItem,
              cartQuantity: cartItem.cartQuantity + (quantity ? quantity : 1),
            } // Change this logic to accept quantity sent instead of hard coded value 1
          : cartItem
      );
      if (this.router.url !== `/stores/${storeName.toLowerCase()}/cart`) {
        this.snackBar.openSnackBar('Product added to cart', 'success-snackbar');
      }
      localStorage.setItem(
        this.getStoreCartKey('cart'),
        JSON.stringify(this.cartItems)
      );
      return this.cartItems;
    } else {
      if (product.product.quantity_in_stock === 0) {
        this.snackBar.openSnackBar(
          'Sorry, this item is no longer in stock',
          'error-snackbar'
        );
        return;
      }
      this.cartItems = [
        ...this.cartItems,
        {
          ...product,
          cartQuantity: quantity ? quantity : 1,
        },
      ];
      if (this.router.url !== `/stores/${storeName.toLowerCase()}/cart`) {
        this.snackBar.openSnackBar('Product added to cart', 'success-snackbar');
      }
      localStorage.setItem(
        this.getStoreCartKey('cart'),
        JSON.stringify(this.cartItems)
      );
      return this.cartItems;
    }
  }

  subtractCartItem(product: any) {
    const storeName = JSON.parse(sessionStorage.getItem('currentStore')!)
      ? JSON.parse(sessionStorage.getItem('currentStore')!).store_name.replace(
          /\s/g,
          '-'
        )
      : null;
    const existingCartItem = this.cartItems.find(
      (item: any) => item.product.product_code === product.product.product_code
    );
    if (existingCartItem.cartQuantity === 1) {
      if (this.router.url === `/stores/${storeName.toLowerCase()}/cart`) {
        this.removeCartItem(product.product.product_code);
        return;
      } else {
        existingCartItem.cartQuantity = 0;
        this.cartItems = this.cartItems.filter(
          (cartItem) =>
            cartItem.product.product_code !== product.product.product_code
        );


        localStorage.setItem(
          this.getStoreCartKey('cart'),
          JSON.stringify(this.cartItems)
        );
        return this.cartItems;
      }
    }
    if (existingCartItem) {
      this.qunatityExceeded = false;
      this.cartItems = this.cartItems.map((cartItem) =>
        cartItem.product.product_code === product.product.product_code
          ? { ...cartItem, cartQuantity: cartItem.cartQuantity - 1 }
          : cartItem
      );
      localStorage.setItem(
        this.getStoreCartKey('cart'),
        JSON.stringify(this.cartItems)
      );
      return this.cartItems;
    }
    return;
  }

  removeCartItem(id: string) {
    let text =
      'You are about to permanently delete this product from your cart';
    if (confirm(text) === true) {
      this.cartItems = this.cartItems.filter((item: any) => {
        return item.product.product_code !== id;
      });
      localStorage.setItem(
        this.getStoreCartKey('cart'),
        JSON.stringify(this.cartItems)
      );
      return this.cartItems;
    } else return;
  }

  removeVariantCartItem(variantObj: any) {
    let text =
      'You are about to permanently delete this product from your cart';
    if (confirm(text) === true) {
      this.cartItems = this.cartItems.filter((item: any) => {
        return (
          item.variantPurchased?.product_variant_code !==
          variantObj?.product_variant_code
        );
      });
      localStorage.setItem(
        this.getStoreCartKey('cart'),
        JSON.stringify(this.cartItems)
      );
      return this.cartItems;
    } else return;
  }

  getcartItems() {
    return JSON.parse(localStorage.getItem(this.getStoreCartKey('cart'))!);
  }

  getItemCount(id: string) {
    const newCart =
      JSON.parse(localStorage.getItem(this.getStoreCartKey('cart'))!) || [];
    const existingCartItem = newCart.find(
      (item: any) => item.product.product_code === id
    );

    if (existingCartItem) {
      return existingCartItem.cartQuantity;
    } else return;
  }

  getVariantCount(variantObj: any) {
    const newCart =
      JSON.parse(localStorage.getItem(this.getStoreCartKey('cart'))!) || [];
    const existingCartItem = newCart.find(
      (item: any) =>
        item.variantPurchased?.product_variant_code ===
        variantObj?.product_variant_code
    );

    if (existingCartItem) {
      return existingCartItem.cartQuantity;
    } else return;
  }

  reduceCartQuantity() {
    if (this.cartItems.length > 0) {
      this.cartCount = this.cartItems.reduce(
        (acc, item) => acc + item.cartQuantity,
        0
      );
      return this.cartCount;
    }
    return 0;
  }

  reduceCartPrice() {
    if (this.cartItems?.length > 0) {
      this.cartTotal = this.cartItems?.reduce(
        (acc, item) =>
          acc +
          item?.cartQuantity *
            (item?.purchasedPrice
              ? item?.purchasedPrice
              : item?.product?.discount > 0
              ? item?.product?.discounted_price
              : item?.product?.price),
        0
      );
      localStorage.setItem(
        this.getStoreCartKey('cartTotal'),
        JSON.stringify(this.cartTotal)
      );
      return this.cartTotal;
    } else {
      this.cartTotal = 0;
      localStorage.setItem(
        this.getStoreCartKey('cartTotal'),
        JSON.stringify(this.cartTotal)
      );
      return this.cartTotal;
    }
  }

  verifyTransaction(transactionDetails: any) {
    return this.https.post(
      `${this.baseUrl}/klump/verify-transaction`,
      transactionDetails
    );
  }

  //   isOrderAvailable() {
  //     if (JSON.parse(localStorage.getItem('orders')!) !== null) {
  //       return true;
  //     } else return false;
  //   }

  //   isDeliveryAvailable() {
  //     if (JSON.parse(sessionStorage.getItem('delivery')!) !== null) {
  //       return true;
  //     } else return false;
  //   }

  saveOrders(items: any) {
    localStorage.setItem('orders', JSON.stringify(items));
    // localStorage.setItem(this.getStoreCartKey('orders'), JSON.stringify(items));
  }

  //   saveDelivery(items: any) {
  //     sessionStorage.setItem('delivery', JSON.stringify(items));
  //   }

  //   changeDeliveryStatus() {
  //     this.delivered = true;
  //   }

  //   getDeliveryStatus() {
  //     return this.delivered;
  //   }
}
