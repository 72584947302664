<div
  class="px-0 py-0 my-0 flex justify-center items-center rounded grow w-full"
>
  <div class="bg-white grow lg:mr-0 xl:mr-6 pb-[4rem] px-2">
    <p
      *ngIf="open === 0"
      class="hidden lg:block text-zestBlack font-semibold text-[16px] md:text-2xl ml-8 pt-10 mb-4 pb-6 "
    >
      My Cart
    </p>
    <p
      *ngIf="open === 3"
      class="hidden lg:block text-zestBlack font-semibold text-2xl ml-8 pt-10 pb-6 border-b"
    >
      Summary
    </p>
    <div *ngIf="cartItems?.length === 0 || !cartItems" class="flex flex-col items-center ">
      <div class="flex items-center justify-center rounded-full h-14 w-14 bg-zestNeutral-200 p-2 mb-[9px]">
         <img src="../../../assets/svg/Bag.svg" alt="" />
      </div>

      <div class="md:max-w-[332px] w-full text-center">
        <p
          class="mt-1 mb-[9px] lg:mt-0 text-zestBlack text-lg  font-medium"
        >
          Your cart is empty.
        </p>
        <p class="text-zestTertiary-500 text-sm font-normal tracking-wide ">
          You don’t have any item on your cart yet, click on the button below to
          shop.
        </p>
      </div>
      <button
            [routerLink]="['/stores', storeName | lowercase]"
            class="text-zestWhite bg-zestBlack border-[#80909D] border text-sm lg:text-base rounded-lg py-4 px-8 mt-8 w-full md:w-2/5"
          >
            Continue Shopping
          </button>
    </div>

    <div
      class="flex justify-between items-center py-8 mt-4 lg:mt-0 mx-0 lg:py-8 lg:mx-8"
      *ngFor="let cartItem of cartItems"
    >
      <div class="flex justify-start items-center px-0">
        <img
          src="{{ cartItem?.product.image_data[0]?.image_source }}"
          alt="cart-product"
          class="mr-4 w-20 h-20 rounded"
        />
        <div>
          <p
            class="font-normal text-sm text-zestLabel lg:font-medium lg:text-base lg:text-zestBlack lg:w-44"
          >
            {{ cartItem?.product.product_name }}
            <span
              class="text-xs text-zestPlaceholder"
              *ngIf="cartItem?.purchasedvariant"
              >({{ cartItem?.purchasedvariant }})</span
            >
          </p>
          <small
            *ngIf="cartItem.purchasedPrice; else discount"
            class="block text-xs pt-1 text-zestBlack lg:hidden font-medium"
          >
            {{
              cartItem?.purchasedPrice * cartItem?.cartQuantity
                | currency : "₦ "
            }}
          </small>
          <ng-template #discount>
            <small
              class="block text-xs pt-1 text-zestBlack lg:hidden font-medium"
            >
              {{
                cartItem?.product.discounted_price &&
                cartItem?.product.discounted_price !== 0
                  ? (cartItem?.product.discounted_price * cartItem?.cartQuantity
                    | currency : "₦ ")
                  : (cartItem?.product.price * cartItem?.cartQuantity
                    | currency : "₦ ")
              }}
            </small>
          </ng-template>
          <div class="flex justify-between items-center lg:hidden">
            <div
              class="flex justify-between items-center bg-white border-[##F4F6F9] rounded mt-3"
            >
              <button
                (click)="
                  cartItem.product.productVariant
                    ? minus(cartItem, cartItem.variantPurchased)
                    : store.subtractCartItem(cartItem)
                "
                [disabled]="
                  store.getItemCount(cartItem?.product.product_code) === 0 ||
                  store.getVariantCount(cartItem.variantPurchased) === 0
                "
                [ngClass]="{
                  invisible: open === 3
                }"
                class="w-8 h-8 border border-[#F4F6F9] focus:border-zestGreen bg-[#F4F6F9] focus:ring-zestGreen rounded-[2px] text-lg"
              >
                -
              </button>
              <p class="px-4" *ngIf="!cartItem.product.productVariant">
                {{ store.getItemCount(cartItem.product.product_code) }}
              </p>
              <p class="px-4" *ngIf="cartItem.product.productVariant">
                {{ store.getVariantCount(cartItem.variantPurchased) }}
              </p>
              <button
                [disabled]="store.qunatityExceeded"
                (click)="
                  cartItem.product.productVariant
                    ? plus(
                        cartItem,
                        cartItem.purchasedvariant,
                        cartItem.purchasedPrice
                      )
                    : store.addCartItem(cartItem)
                "
                [ngClass]="{
                  invisible: open === 3
                }"
                class="w-8 h-8 border border-[#F4F6F9] focus:border-zestGreen bg-[#F4F6F9] focus:ring-zestGreen rounded-[2px] text-lg"
              >
                +
              </button>
            </div>
          </div>
          <div
            (click)="
              cartItem.product.productVariant
                ? store.removeVariantCartItem(cartItem.variantPurchased)
                : store.removeCartItem(cartItem.product.product_code)
            "
            *ngIf="open !== 3"
            class="hidden lg:flex items-center pt-3 text-[#4F499F] cursor-pointer"
          >
            <img src="../../../assets/svg/Delete.svg" alt="" class="w-3 mr-2" />
            <p class="text-sm">Remove Item</p>
          </div>
        </div>
      </div>
      <div
        class="flex items-end self-end lg:hidden pt-5 text-[#4F499F]"
        (click)="
          cartItem.product.productVariant
            ? store.removeVariantCartItem(cartItem.variantPurchased)
            : store.removeCartItem(cartItem.product.product_code)
        "
      >
        <img src="../../../assets/svg/Delete.svg" alt="" class="w-3 ml-8" />
      </div>
      <div class="hidden lg:block border border-zestNeutral">
        <div class="flex justify-between items-center bg-white rounded m-2">
          <button
            (click)="
              cartItem.product.productVariant
                ? minus(cartItem, cartItem.variantPurchased)
                : store.subtractCartItem(cartItem)
            "
            [disabled]="
              store.getItemCount(cartItem?.product.product_code) === 0
            "
            [ngClass]="{
              invisible: open === 3
            }"
            class="w-8 h-8 border border-zestNeutral focus:border-zestGreen bg-zestNeutral focus:ring-zestGreen rounded-[2px] text-xl"
          >
            -
          </button>
          <p class="px-4">
            {{
              cartItem?.variantPurchased
                ? store.getVariantCount(cartItem.variantPurchased)
                : store.getItemCount(cartItem.product.product_code)
            }}
          </p>

          <button
            (click)="
              cartItem.product.productVariant
                ? plus(
                    cartItem,
                    cartItem.purchasedvariant,
                    cartItem.purchasedPrice
                  )
                : store.addCartItem(cartItem)
            "
            [ngClass]="{
              invisible: open === 3
            }"
            class="w-8 h-8 border border-zestNeutral focus:border-zestGreen disabled:text-ZTN-300 bg-zestNeutral focus:ring-zestGreen rounded-[2px] text-xl"
          >
            +
          </button>
        </div>
      </div>
      <div
        class="hidden lg:block text-right"
        *ngIf="cartItem.purchasedPrice; else discounted"
      >
        <p class="text-zestBlack text-lg text-right font-semibold">
          {{
            cartItem?.purchasedPrice * cartItem?.cartQuantity | currency : "₦ "
          }}
        </p>
        <small class="text-sm text-right font-normal text-zestLabel"
          >{{ cartItem?.purchasedPrice | currency : "₦ " }} *
          {{ cartItem?.cartQuantity }}
        </small>
      </div>
      <ng-template #discounted>
        <div class="hidden lg:block text-right right">
          <p class="text-zestBlack text-lg text-right font-semibold">
            {{
              cartItem?.product.discount > 0
                ? (cartItem?.product.discounted_price * cartItem?.cartQuantity
                  | currency : "₦ ")
                : (cartItem?.product.price * cartItem?.cartQuantity
                  | currency : "₦ ")
            }}
          </p>
          <small class="text-sm text-right font-normal text-zestLabel"
            >{{
              cartItem?.product?.discount > 0
                ? (cartItem?.product.discounted_price | currency : "₦ ")
                : (cartItem?.product.price | currency : "₦ ")
            }}
            * {{ cartItem?.cartQuantity }}</small
          >
        </div>
      </ng-template>

      <hr />
    </div>
  </div>
</div>

<section
  appScrollToTop
  [ngClass]="{ hidden: store.showMobile }"
  class="font-mont lg:bg-[#FBFBFB]"
  *ngIf="!store.isCartShown() && pageLoad"
>
  <app-single-store-header
    class="hidden lg:block"
    [storeName]="stores"
  ></app-single-store-header>
  <div
    class="px-6 py-4 lg:py-0 flex items-center lg:mx-40 lg:px-0 justify-between shadow-md lg:shadow-none lg:justify-start"
    (click)="onBackClick()"
  >
    <img src="../../../assets/svg/back.svg" alt="back" class="cursor-pointer" />
    <p
      class="hidden lg:block font-medium text-zestBlack text-lg font-mont px-4 py-6"
    >
      Back
    </p>
    <p class="text-zestBlack block lg:hidden font-semibold text-2xl">My Cart</p>
    <p class="block lg:hidden text-2xl">&nbsp;</p>
  </div>

  <main class="block mx-6 xl:flex lg:mx-40">
    <div class="px-0 py-0 my-0 rounded xl:pr-6 grow w-full xl:w-8/12">
      <div class="bg-white grow lg:mr-0 xl:mr-6">
        <p
          class="hidden lg:block text-zestBlack font-semibold text-2xl ml-8 py-7"
        >
          My Cart
        </p>
        <p
          *ngIf="cartItems?.length === 0 || !cartItems"
          class="py-7 mt-3 lg:mt-0 text-zestBlack text-lg text-center font-medium"
        >
          Your cart is empty
        </p>
        <div
          class="flex justify-between items-center py-8 mt-4 lg:mt-0 mx-0 lg:py-8 lg:mx-8"
          *ngFor="let cartItem of cartItems"
        >
          <div class="flex justify-start items-center px-0">
            <img
              src="{{ cartItem?.product.image_data[0]?.image_source }}"
              alt="cart-product"
              class="mr-4 w-20 h-20 rounded"
            />
            <div>
              <p
                class="font-normal text-sm text-zestLabel lg:font-medium lg:text-base lg:text-zestBlack lg:w-44"
              >
                {{ cartItem?.product?.product_name }}
                <span
                  class="text-xs text-zestPlaceholder"
                  *ngIf="cartItem?.purchasedvariant"
                  >({{ cartItem?.purchasedvariant }})</span
                >
              </p>
              <small
                *ngIf="cartItem?.purchasedPrice; else discount"
                class="block text-xs pt-1 text-zestBlack lg:hidden font-medium"
              >
                {{
                  cartItem?.purchasedPrice * cartItem?.cartQuantity
                    | currency : "₦ "
                }}
              </small>
              <ng-template #discount>
                <small
                  class="block text-xs pt-1 text-zestBlack lg:hidden font-medium"
                >
                  {{
                    cartItem?.product?.discount > 0
                      ? (cartItem?.product?.discounted_price *
                          cartItem?.cartQuantity | currency : "₦ ")
                      : (cartItem?.product?.price * cartItem?.cartQuantity
                        | currency : "₦ ")
                  }}
                </small>
              </ng-template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</section>
