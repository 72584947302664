<!-- header.component.html -->
<!-- Navbar -->
<div
  class="fixed inset-x-0 top-0 flex items-center justify-between py-4 px-[3rem] lg:px-[7rem] bg-black bg-opacity-50 z-50 "
>
  <!-- Logo -->
  <div class="text-[white] flex space-x-2 items-center">
    <div *ngIf="!imageLink">
        <ngx-avatars
          [name]="displayName"
          initialsSize="0"
          cornerRadius="4"
          [round]="false"
          bgColor="inherit"
          fgColor="#ffffff"
          size="60"
        ></ngx-avatars>


    </div>
     <img
      *ngIf="imageLink"
      [src]="imageLink"
      alt="Logo"
      class="w-12 h-12 object-cover mr-2"
    />

    <div
      class="hidden md:flex items-center bg-white rounded-lg p-2 w-[400px] md:w-[450px] lg:w-[555px] "
    >
      <div class="relative">
        <select
        name="selectedOption"
        (change)="onFilterWithPrice($event)"
          class="hidden md:block placeholder-zestPlaceholder border-hidden focus:border-hidden focus:ring-zestGreen  text-sm lg:text-base  text-black font-medium pr-6"
        >
           <option *ngFor="let option of options">{{ option }}</option>
          <!-- Add other options here -->
        </select>

      </div>
      <div class="border h-6 border-r-1 border-ZTN-200"></div>
      <div class="relative w-3/4 xl:w-fit md:mr-2 xl:mr-0 ">
        <input
          type="text"
         (keyup.enter)="onSearchTermChange($event)"
          placeholder="Search by product name"
          class="w-full text-black text-sm sm:pr-5 border-hidden mr-3 focus:border-hidden focus:ring-zestGreen sm:pl-4  rounded  xl:px-4 font-mont lg:text-base xl:w-[384px]"
          #searchInput
        />
        <button (click)="onSearchIconClick(searchInput.value)">
        <img
          src="../../../assets/svg/Search.svg"
          alt="search"
          class="absolute w-4 h-4 right-2 xl:right-5 bottom-3 mr-1 xl:mr-4"
        />
      </button>
      </div>
    </div>
  </div>

  <!-- Search Input -->
  <div>
   <div
  class="flex items-center rounded-[8px] p-2 bg-zestWhite"
  [routerLink]="['/stores', bizName, 'cart']"
>
  <img class="mr-2 md:mr-0" src="../../../assets/svg/Buy.svg" alt="cart" />
  <p
    class="block text-xs lg:hidden pl-[6px] text-zestWhite bg-zestGreen rounded py-[2px] px-2"
  >
    {{ store.getCartLenght() }}
  </p>
  <p
    class="px-2 font-mont text-zestBlack font-medium text-sm leading-6 cursor-pointer hidden lg:block"
  >
    My Cart
    <span class=" text-zestWhite bg-zestGreen rounded py-1 px-2">
      {{ store.getCartLenght() }}
    </span>
  </p>
</div>
  </div>
</div>

<!-- Banner & Logo Section  -->
<div class="relative h-64">
  <img
    *ngIf="!bannerLink"
    src="../../assets/svg/headerBg.png"
    alt="Banner Image"
    class="w-full h-full object-cover"
  />
  <img
    *ngIf="bannerLink"
    [src]="bannerLink"
    alt="Banner Image"
    class="w-full h-full object-cover"
  />

  <div
    class="logo-div cursor-pointer absolute bottom-[-45px] lg:bottom-[-75px] left-4 md:left-16 lg:left-[85px] w-[100px] h-[100px] md:w-[130px] md:h-[130px] lg:w-[170px] lg:h-[170px] flex items-center justify-center bg-ZTN-200"
    [routerLink]="['/stores', bizName]"
  >
    <div>
      <div *ngIf="!imageLink">
        <ngx-avatars
          [name]="displayName"
          initialsSize="0"
          cornerRadius="8"
          [round]="false"
          bgColor="inherit"
          fgColor=""
          size="160"
        ></ngx-avatars>
      </div>
    </div>
    <img
      *ngIf="imageLink"
      [src]="imageLink"
      alt="Logo"
      class="w-full h-full object-cover"
    />
  </div>
  <div
    class="absolute bottom-[-33px] left-[8rem] md:left-[13rem] lg:left-[18rem] text-zestBlack text-sm md:text-[32px] font-semibold whitespace-nowrap"
  >
    {{ displayName }}
  </div>
</div>
