<div>
  <div class="relative sm:w-11/12 md:w-full bg-white rounded-lg" appScrollToTop>
    <div class="px-auto rounded-lg">
      <div class="p-0 lg:p-8 pt-2 pb-5">
        <p
          class="hidden lg:block text-zestBlack font-semibold text-lg pt-10 pb-6 border-b"
        >
          Delivery Details
        </p>
        <form
          [formGroup]="deliveryDetail"
          id="paymentForm"
        >
          <div class="py-2 w-full">
            <div formGroupName="addressDTO">
              <div class="flex pb-6 justify-between">
                <div class="grow w-1/2 mr-6">
                  <label
                    class="block pt-6 pb-2 font-normal leading-4 text-sm tracking-[0.01em] text-zestGrey"
                  >
                    First Name
                  </label>
                  <input
                    class="bg-white w-full h-14 outline-hidden placeholder-zestPlaceholder rounded border-[1px] border-solid border-[#c1c7d0] p-4 focus:border-zestGreen focus:ring-zestGreen"
                    type="text"
                    placeholder="First name"
                    name="first_name"
                    formControlName="first_name"
                    required
                  />
                  <span
                    class="text-red-500 pt-1"
                    *ngIf="
                      !deliveryDetail.get('addressDTO')?.get('first_name')
                        ?.valid &&
                      deliveryDetail.get('addressDTO')?.get('first_name')?.touched
                    "
                    >*First name is required</span
                  ><span
                  class="block text-red-500 pt-1"
                  *ngIf="
                    deliveryDetail.get('addressDTO')?.get('first_name')
                      ?.hasError('pattern') &&
                    deliveryDetail.get('addressDTO')?.get('first_name')?.touched
                  "
                  >*Please input only alphabets in this field</span
                >
                </div>
                <div class="grow w-1/2">
                  <label
                    class="block pt-6 pb-2 font-normal leading-4 text-sm tracking-[0.01em] text-zestGrey"
                  >
                    Last Name
                  </label>
                  <input
                    class="bg-white w-full h-14 outline-hidden placeholder-zestPlaceholder rounded border-[1px] border-solid border-[#c1c7d0] p-4 focus:border-zestGreen focus:ring-zestGreen"
                    type="text"
                    placeholder="Last name"
                    name="last_name"
                    formControlName="last_name"
                    required
                  />
                  <span
                    class="text-red-500 pt-1"
                    *ngIf="
                      !deliveryDetail.get('addressDTO')?.get('last_name')
                        ?.valid &&
                      deliveryDetail.get('addressDTO')?.get('last_name')?.touched
                    "
                    >*Last name is required</span
                  >
                  <span
                    class="block text-red-500 pt-1"
                    *ngIf="
                      deliveryDetail.get('addressDTO')?.get('last_name')
                        ?.hasError('pattern') &&
                      deliveryDetail.get('addressDTO')?.get('last_name')?.touched
                    "
                    >*Please input only alphabets in this field</span
                  >
                </div>
              </div>
              <div class="flex pb-6 justify-between">
                <div class="grow w-1/2 mr-6">
                  <label
                    class="block pb-2 font-normal leading-4 text-sm tracking-[0.01em] text-zestGrey"
                  >
                    Email
                  </label>
                  <input
                    class="bg-white w-full h-14 outline-hidden placeholder-zestPlaceholder rounded border-[1px] border-solid border-[#c1c7d0] p-4 focus:border-zestGreen focus:ring-zestGreen"
                    type="text"
                    placeholder="Enter email address"
                    name="email"
                    formControlName="email"
                    required
                  />
                  <div
                    class="text-red-500 pt-1"
                    *ngIf="
                      !deliveryDetail.get('addressDTO')?.get('email')?.valid &&
                      deliveryDetail.get('addressDTO')?.get('email')?.touched
                    "
                  >
                    <span
                      *ngIf="
                        deliveryDetail.get('addressDTO')?.get('email')?.errors
                          ?.required
                      "
                      >*Email address is required</span
                    >
                    <span
                      *ngIf="
                        deliveryDetail.get('addressDTO')?.get('email')?.hasError('pattern')
                      "
                      >*Email address is invalid</span
                    >
                  </div>
                </div>
                <div class="grow w-1/2">
                  <label
                    class="block pb-2 font-normal leading-4 text-sm tracking-[0.01em] text-zestGrey"
                  >
                    Phone Number
                  </label>
                  <div>
                    <div class="relative rounded-md shadow-sm">
                      <div class="absolute inset-y-0 left-0 flex items-center">
                        <label for="country" class="sr-only"
                          >Phone Number</label
                        >
                        <select
                          id="phoneNumber"
                          name="countryCode"
                          required
                          autocomplete="phone"
                          class="h-full rounded-md border-transparent bg-transparent py-0 pl-3 pr-10 focus:border-zestGreen focus:ring-zestGreen sm:text-sm"
                        >
                          <option *ngFor="let code of codes">
                            {{ code }}
                          </option>
                        </select>
                      </div>
                      <input
                        type="tel"
                        name="phone"
                        id="phone-number"
                        formControlName="phone"
                        class="bg-white w-full h-14 outline-hidden rounded placeholder-zestPlaceholder border-[1px] border-solid border-[#c1c7d0] pl-[6rem] focus:border-zestGreen focus:ring-zestGreen"
                        placeholder="909876543"
                      />
                    </div>
                    <div>
                      <span
                        class="text-red-500 pt-1"
                        *ngIf="
                          !deliveryDetail.get('addressDTO')?.get('phone')
                            ?.valid &&
                          deliveryDetail.get('addressDTO')?.get('phone')?.touched
                        "
                        >*Phone number is required</span
                      >
                      <!-- <div
                        class="text-red-500 pt-1"
                        *ngIf="
                          deliveryDetail.get('addressDTO')?.get('phone')?.errors
                            ?.pattern ||
                          deliveryDetail.get('addressDTO')?.get('phone')?.errors
                            ?.maxlength ||
                          deliveryDetail.get('addressDTO')?.get('phone')?.errors
                            ?.minlength
                        "
                      >
                        *Phone number must be at most 10 digits without the
                        first zero
                      </div> -->
                    </div>
                  </div>
                </div>
              </div>

              <!-- <div class="flex">
                <div class="grow w-1/2">
                  <label
                    class="block pb-2 font-normal leading-4 text-sm tracking-[0.01em] text-zestGrey"
                  >
                    Country
                  </label>

                  <select
                    id="country"
                    name="country"
                    required
                    formControlName="country"
                    class="bg-white w-full h-14 outline-hidden rounded border-[1px] placeholder-zestPlaceholder border-solid border-[#c1c7d0] p-3 focus:border-zestGreen focus:ring-zestGreen"
                  >
                    <option *ngFor="let country of countries" value="NG">
                      {{ country }}
                    </option>
                  </select>
                  <span
                    class="text-red-500 pt-1"
                    *ngIf="
                      !deliveryDetail.get('addressDTO')?.get('country')?.valid &&
                      deliveryDetail.get('addressDTO')?.get('country')?.touched
                    "
                    >*Please select a country</span
                  >
                </div>
              </div>
              <div class="flex justify-between">
                <div class="grow w-1/2 mr-6">
                  <label
                    class="block pt-6 pb-2 font-normal leading-4 text-sm tracking-[0.01em] text-zestGrey"
                  >
                    State
                  </label>

                  <select
                    id="state"
                    name="state"
                    required
                    formControlName="state"
                    (change)="stateChanged()"
                    class="bg-white w-full h-14 outline-hidden rounded border-[1px] placeholder-zestPlaceholder border-solid border-[#c1c7d0] p-3 focus:border-zestGreen focus:ring-zestGreen"
                  >
                    <option value="">Select a state</option>
                    <option *ngFor="let state of states" [value]="state.name">
                      {{ state.name }}
                    </option>
                  </select>
                  <span
                    class="text-red-500 pt-1"
                    *ngIf="
                      !deliveryDetail.get('state')?.valid &&
                      deliveryDetail.get('state')?.touched
                    "
                    >*Please select a state</span
                  >
                </div>
                <div class="grow w-1/2">
                  <label
                    class="block pt-6 pb-2 font-normal leading-4 text-sm tracking-[0.01em] text-zestGrey"
                  >
                    City
                    <span class="pl-1 inline-flex">
                      <small class="font-semibold text-zestBlack"
                        >(select city closest to you)</small
                      >
                    </span>
                  </label>
                  <select
                    id="city"
                    name="city"
                    formControlName="city"
                    class="bg-white w-full h-14 outline-hidden rounded border-[1px] placeholder-zestPlaceholder border-solid border-[#c1c7d0] p-3 focus:border-zestGreen focus:ring-zestGreen"
                  >
                    <option value="">Select a city</option>
                    <option *ngFor="let city of cities" [value]="city">
                      {{ city }}
                    </option>
                  </select>

                  <span
                    class="text-red-500 pt-1"
                    *ngIf="
                      !deliveryDetail.get('addressDTO')?.get('city')?.valid &&
                      deliveryDetail.get('addressDTO')?.get('city')?.touched
                    "
                    >*City is required</span
                  >
                </div>
              </div> -->
              <div class="flex">
                <div class="grow w-1/2">
                  <label
                    class="block pb-2 font-normal leading-4 text-sm tracking-[0.01em] text-zestGrey"
                  >
                    Shipping Option
                  </label>
                  <mat-select
                      id="region"
                      name="region"
                      formControlName="region"
                      placeholder="Select shipping method"
                      (selectionChange)="selectedValue($event)"
                      class="font-mont bg-white w-full h-14 outline-hidden rounded border placeholder-zestPlaceholder border-solid border-[#c1c7d0] p-3 focus:border-zestGreen focus:ring-zestGreen"
                    >
                      <mat-option
                        [value]="type" *ngFor="let type of deliveryTypes"
                        class="font-mont"
                        >{{ type }}</mat-option
                      >
                    </mat-select>

                  <!-- <select
                    id="region"
                    name="region"
                    (change)="selectedValue($event)"
                    required
                    class="bg-white w-full h-14 outline-hidden rounded border placeholder-zestPlaceholder border-solid border-[#c1c7d0] p-3 focus:border-zestGreen focus:ring-zestGreen"
                  >
                    <option value="select a shipping method">
                      Select shipping method
                    </option>
                    <option [value]="type" *ngFor="let type of deliveryTypes">
                      {{ type }}
                    </option>
                  </select> -->
                  
                  <span
                    class="text-red-500 pt-1"
                    *ngIf="
                      selectedShipping === 'select a shipping method'
                    "
                    >*Please select a shipping option to proceed</span
                  >
                </div>
              </div>
              <div class="text-left">
                <label
                  class="block pt-6 pb-2 font-normal leading-4 text-sm tracking-[0.01em] text-zestGrey"
                >
                  Got a discount code? Use it below
                </label>
                <div class="flex relative">
                  <input
                    class="bg-white h-[56px] w-full outline-hidden rounded-l border-[1px] border-solid border-[#c1c7d0] p-4 disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200"
                    type="text"
                    placeholder="Enter Code"
                    name="checkoutCode"
                    readonly
                  />
                  <div
                    class="absolute cursor-pointer px-5 py-3 top-1.5 w-28 right-1 rounded bg-zestBlack border-transparent"
                  >
                    <p
                      class="text-white flex justify-center items-center font-medium text-sm"
                    >
                      Apply
                    </p>
                  </div>
                </div>
              </div>
              <div *ngIf="selectedShipping === 'Pickup in store'"
                class="border border-ZTP-700 bg-ZTP-50 p-3 rounded-md mt-6"
              >
                <div class="flex items-center justify-start">
                  <img
                    src="../../../assets/svg/pickupWarn.svg"
                    alt="warn"
                    class="w-5"
                  />
                  <p class="font-semibold text-zestBlack text-sm pl-3">
                    Pickup Information
                  </p>
                </div>
                <div class="">
                  <ng-template #ownshipping>
                    <p class="font-semibold text-zestBlack text-sm">Shipping</p>
                  </ng-template>
                  <p
                    class="text-xs font-normal text-zestTertiary-700 pt-2 leading-4"
                  >
                    The Pickup address for your order will be sent to your
                    email.
                  </p>
                </div>
              </div>
              <!-- <div class="grow w-full mr-3">
                <label
                  class="block pt-6 pb-2 font-normal leading-4 text-sm tracking-[0.01em] text-zestGrey"
                >
                  Delivery Address
                </label>
                <input
                  class="bg-white w-full h-14 outline-hidden placeholder-zestPlaceholder rounded border-[1px] border-solid border-[#c1c7d0] p-4 focus:border-zestGreen focus:ring-zestGreen"
                  type="text"
                  placeholder="Enter delivery address"
                  name="line1"
                  formControlName="line1"
                  required
                />
                <span
                  class="text-red-500 pt-1"
                  *ngIf="
                    !deliveryDetail.get('addressDTO')?.get('line1')?.valid &&
                    deliveryDetail.get('addressDTO')?.get('line1')?.touched
                  "
                  >*Delivery address is required</span
                >
              </div> -->
              <!-- <div formGroupName="metadata">
                <label
                  class="block pt-6 pb-2 font-normal leading-4 text-sm tracking-[0.01em] text-zestGrey"
                >
                  Delivery Note
                </label>
                <textarea
                  class="bg-white w-full outline-hidden resize-none placeholder-zestPlaceholder rounded border border-solid border-[#c1c7d0] p-4 focus:border-zestGreen focus:ring-zestGreen"
                  type="text"
                  rows="5"
                  placeholder="Enter delivery note"
                  name="delivery_note"
                  formControlName="delivery_note"
                ></textarea>
              </div> -->
            </div>

            <!-- <div class="grow pt-6">
              <div class="flex justify-between items-start pt-3 pb-4">
                <p>Items</p>
                <div class="flex justify-between items-center">
                  <p class="">{{ total | currency : "₦ " }}</p>
                </div>
              </div>

              <div
                *ngIf="deliveryCharge; else fees"
                class="flex justify-between items-start"
              >
                <p>Shipping</p>
                <div class="flex justify-between items-center">
                  <p class="">{{ deliveryCharge | currency : "₦ " }}</p>
                </div>
              </div>
              <ng-template #fees>
                <div class="flex justify-between items-start">
                  <p>Shipping</p>
                  <div class="flex justify-between items-center">
                    <p class="">NGN 0.00</p>
                  </div>
                </div>
              </ng-template>
              <div
                class="flex justify-between items-start pt-4 text-zestBlack font-bold"
              >
                <p>Total</p>
                <div class="flex justify-between items-center">
                  <p class="">
                    {{ total + deliveryCharge || total | currency : "₦ " }}
                  </p>
                </div>
              </div>
            </div>
            <div
              class="flex items-start justify-between border border-ZTN-600 bg-ZTN-50 p-3 rounded-md mt-6"
            >
              <div>
                <img
                  src="../../../assets/svg/warning.svg"
                  alt="warn"
                  class="w-16"
                />
              </div>
              <div class="ml-3">
                <p class="font-semibold text-zestBlack text-sm">
                  No Shipping Available
                </p>
                <ng-template #ownshipping>
                  <p class="font-semibold text-zestBlack text-sm">Shipping</p>
                </ng-template>
                <p
                  class="text-xs font-normal text-zestTertiary-700 pt-2 leading-4"
                >
                  Due to the fact that this merchant does not have shipping fees
                  established for their store, you might not be able to complete
                  your purchase.
                </p>
                <ng-template #owntext>
                  <p
                    class="text-xs font-normal text-zestTertiary-700 pt-2 leading-4"
                  >
                    Please note that this merchant only offers in-store pickups.
                    To pickup your order,
                  </p>
                </ng-template>
                <p
                  class="text-zestTertiary-700 font-normal text-xs pt-2 leading-4"
                >
                  Please contact merchant via <br />
                  <span>Email:</span>
                  <br />
                  <span>Phone: </span>
                </p>
              </div>
            </div>
            <button
              type="submit"
              [disabled]="!deliveryDetail.valid"
              class="bg-zestBlack text-white text-base rounded py-3 px-6 mt-6 w-full font-semibold disabled:bg-gray-300 disabled:cursor-not-allowed"
            >
              Place Order
            </button> -->
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
