<div>
  <div class="relative sm:w-11/12 md:w-full bg-white rounded-lg" appScrollToTop>
    <div class="px-auto rounded-lg">
      <div class="p-0 lg:p-8 pt-2 pb-5">
        <p
          class="hidden lg:block text-zestBlack font-semibold text-lg pt-10 pb-6 border-b"
        >
          Shipping Details
        </p>
        <form [formGroup]="shipping" id="paymentForm">
          <div class="py-2 w-full">
            <div formGroupName="addressDTO">
              <div class="block lg:flex lg:justify-between">
                <div class="grow w-full lg:w-1/2 mr-6">
                  <label
                    class="block pt-6 pb-2 font-normal leading-4 text-sm tracking-[0.01em] text-zestGrey"
                  >
                    State
                  </label>
                  <mat-select
                    id="state"
                    name="state"
                    required
                    formControlName="state"
                    panelClass="mat-select-style"
                    placeholder="Select a state"
                    (selectionChange)="stateChanged()"
                    class="font-mont bg-white w-full h-14 outline-hidden rounded border placeholder-zestPlaceholder border-solid border-[#c1c7d0] p-3 focus:border-zestGreen focus:ring-zestGreen"
                  >
                    <mat-option class="font-mont flex items-center"
                    
                      *ngFor="let state of states" [value]="state.name"
                      >{{ state.name }}</mat-option
                    >
                  </mat-select>
                  <span
                    class="text-red-500 pt-1"
                    *ngIf="
                      !shipping.get('state')?.valid &&
                      shipping.get('state')?.touched
                    "
                    >*Please select a state</span
                  >
                </div>
                <div class="grow w-full lg:w-1/2">
                  <label
                    class="block pt-6 pb-2 font-normal leading-4 text-sm tracking-[0.01em] text-zestGrey"
                  >
                    City
                    <span class="pl-1 inline-flex">
                      <small class="font-semibold text-zestBlack"
                        >(select city closest to you)</small
                      >
                    </span>
                  </label>
                  <mat-select
                    id="city"
                    name="city"
                    formControlName="city"
                    placeholder="Select a city"
                    class="font-mont bg-white w-full h-14 outline-hidden rounded border placeholder-zestPlaceholder border-solid border-[#c1c7d0] p-3 focus:border-zestGreen focus:ring-zestGreen"
                  >
                    <mat-option class="font-mont"
                      *ngFor="let city of cities" [value]="city"
                      >{{ city }}</mat-option
                    >
                  </mat-select>

                  <span
                    class="text-red-500 pt-1"
                    *ngIf="
                      !shipping.get('addressDTO')?.get('city')?.valid &&
                      shipping.get('addressDTO')?.get('city')?.touched
                    "
                    >*City is required</span
                  >
                </div>
              </div>
              <div class="flex">
                <div class="grow w-1/2">
                  <label
                    class="block pt-6 pb-2 font-normal leading-4 text-sm tracking-[0.01em] text-zestGrey"
                  >
                    Country
                  </label>
                  <mat-select
                    id="country"
                    name="country"
                    required
                    formControlName="country"
                    placeholder="Select country"
                    class="font-mont bg-white w-full h-14 outline-hidden rounded border placeholder-zestPlaceholder border-solid border-[#c1c7d0] p-3 focus:border-zestGreen focus:ring-zestGreen"
                  >
                    <mat-option class="font-mont"
                      *ngFor="let country of countries" value="NG"
                      >{{ country }}</mat-option
                    >
                  </mat-select>
                  <span
                    class="text-red-500 pt-1"
                    *ngIf="
                      !shipping.get('addressDTO')?.get('country')?.valid &&
                      shipping.get('addressDTO')?.get('country')?.touched
                    "
                    >*Please select a country</span
                  >
                </div>
              </div>
              <div class="grow w-full mr-3">
                <label
                  class="block pt-6 pb-2 font-normal leading-4 text-sm tracking-[0.01em] text-zestGrey"
                >
                  Delivery Address
                </label>
                <input
                  class="bg-white w-full h-14 outline-hidden placeholder-zestPlaceholder rounded border-[1px] border-solid border-[#c1c7d0] p-4 focus:border-zestGreen focus:ring-zestGreen"
                  type="text"
                  placeholder="Enter delivery address"
                  name="line1"
                  formControlName="line1"
                  required
                />
                <div class="flex justify-start items-center">
                  <span
                    *ngIf="
                      shipping.get('addressDTO')?.get('line1')?.valid &&
                      availableShipping.includes(
                        'USE_ZEST_SHIPPING_PARTNERS'
                      ) &&
                      !rates
                    "
                    class="text-ZTP-800 underline underline-offset-4 text-base leading-6 block font-medium pt-2 cursor-pointer mr-2"
                    (click)="getShippingRate()"
                    >Get Shipping Rate</span
                  >
                  <mat-spinner
                    [diameter]="14"
                    class="mt-2"
                    *ngIf="store.fetchingRates"
                  ></mat-spinner>
                </div>

                <span
                  class="text-red-500 pt-1 block"
                  *ngIf="
                    !shipping.get('addressDTO')?.get('line1')?.valid &&
                    shipping.get('addressDTO')?.get('line1')?.touched
                  "
                  >*Delivery address is required</span
                >
              </div>
              <div class="">
                <div
                  *ngIf="
                    rates &&
                    availableShipping.includes('USE_ZEST_SHIPPING_PARTNERS')
                  "
                  class="grow"
                >
                  <label
                    class="block pt-6 pb-2 font-normal leading-4 text-sm tracking-[0.01em] text-zestGrey"
                  >
                    Shipping Partner
                  </label>
                  <mat-select
                    placeholder="Select shipping partner"
                    (selectionChange)="selectedRate($event)"
                    class="font-mont bg-white w-full h-14 outline-hidden rounded border placeholder-zestPlaceholder border-solid border-[#c1c7d0] p-3 focus:border-zestGreen focus:ring-zestGreen"
                  >
                    <mat-option
                      *ngFor="let rate of rates"
                      class="font-mont"
                      [value]="rate.rate_id"
                      >{{ rate.carrier }} -
                      {{ rate.amount | currency : "₦ " }} (
                      {{ rate.delivery_time }})</mat-option
                    >
                  </mat-select>

                  <span
                    class="text-red-500 pt-1"
                    *ngIf="
                      !shipping.get('addressDTO')?.get('region')?.valid &&
                      shipping.get('addressDTO')?.get('region')?.touched
                    "
                    >*Region is required</span
                  >
                </div>
                <div
                  *ngIf="
                    availableShipping.includes('I_WILL_MANAGE_MY_OWN_SHIPPING')
                  "
                >
                  <div class="grow w-full">
                    <label
                      class="block pt-6 pb-2 font-normal leading-4 text-sm tracking-[0.01em] text-zestGrey"
                    >
                      Region/Area
                    </label>
                    <mat-select
                      id="region"
                      name="region"
                      formControlName="region"
                      placeholder="Select shipping partner"
                      class="font-mont bg-white w-full h-14 outline-hidden rounded border placeholder-zestPlaceholder border-solid border-[#c1c7d0] p-3 focus:border-zestGreen focus:ring-zestGreen"
                    >
                      <mat-option
                        *ngFor="let regionItem of regions"
                        class="font-mont"
                        [value]="regionItem?.area"
                        >{{ regionItem?.area }} - {{ regionItem?.fee }}</mat-option
                      >
                    </mat-select>

                    <!-- <select
                      id="region"
                      name="region"
                      formControlName="region"
                      required
                      class="bg-white w-full h-14 outline-hidden rounded border placeholder-zestPlaceholder border-solid border-[#c1c7d0] p-3 focus:border-zestGreen focus:ring-zestGreen"
                    >
                      <option value="Select a area for delivery">
                        Select a region/area for delivery
                      </option>
                      <option
                        *ngFor="let regionItem of regions"
                        [value]="regionItem?.area"
                      >
                        {{ regionItem?.area }} - {{ regionItem?.fee }}
                      </option>
                    </select> -->
                    <span
                      class="text-red-500 pt-1"
                      *ngIf="
                        !shipping.get('addressDTO')?.get('region')?.valid &&
                        shipping.get('addressDTO')?.get('region')?.touched
                      "
                      >*Region is required</span
                    >
                  </div>
                </div>
              </div>

              <!--<div
                  class="border border-ZTP-700 bg-ZTP-50 p-3 rounded-md mt-6"
                >
                  <div class="flex items-center justify-start">
                    <img
                      src="../../../assets/svg/pickupWarn.svg"
                      alt="warn"
                      class="w-5"
                    />
                    <p class="font-semibold text-zestBlack text-sm pl-3">
                      Pickup Information
                    </p>
                  </div>
                  <div class="">
                    <ng-template #ownshipping>
                      <p class="font-semibold text-zestBlack text-sm">Shipping</p>
                    </ng-template>
                    <p
                      class="text-xs font-normal text-zestTertiary-700 pt-2 leading-4"
                    >
                      The Pickup address for your order will be sent to your
                      email.
                    </p>
                  </div>
                </div> -->
              <div formGroupName="metadata">
                <label
                  class="block pt-6 pb-2 font-normal leading-4 text-sm tracking-[0.01em] text-zestGrey"
                >
                  Delivery Note
                </label>
                <textarea
                  class="bg-white w-full outline-hidden resize-none placeholder-zestPlaceholder rounded border border-solid border-[#c1c7d0] p-4 focus:border-zestGreen focus:ring-zestGreen"
                  type="text"
                  rows="5"
                  placeholder="Enter delivery note"
                  name="delivery_note"
                  formControlName="delivery_note"
                ></textarea>
              </div>
            </div>

            <!-- <div class="grow pt-6">
                <div class="flex justify-between items-start pt-3 pb-4">
                  <p>Items</p>
                  <div class="flex justify-between items-center">
                    <p class="">{{ total | currency : "₦ " }}</p>
                  </div>
                </div>
  
                <div
                  *ngIf="deliveryCharge; else fees"
                  class="flex justify-between items-start"
                >
                  <p>Shipping</p>
                  <div class="flex justify-between items-center">
                    <p class="">{{ deliveryCharge | currency : "₦ " }}</p>
                  </div>
                </div>
                <ng-template #fees>
                  <div class="flex justify-between items-start">
                    <p>Shipping</p>
                    <div class="flex justify-between items-center">
                      <p class="">NGN 0.00</p>
                    </div>
                  </div>
                </ng-template>
                <div
                  class="flex justify-between items-start pt-4 text-zestBlack font-bold"
                >
                  <p>Total</p>
                  <div class="flex justify-between items-center">
                    <p class="">
                      {{ total + deliveryCharge || total | currency : "₦ " }}
                    </p>
                  </div>
                </div>
              </div>
              <div
                class="flex items-start justify-between border border-ZTN-600 bg-ZTN-50 p-3 rounded-md mt-6"
              >
                <div>
                  <img
                    src="../../../assets/svg/warning.svg"
                    alt="warn"
                    class="w-16"
                  />
                </div>
                <div class="ml-3">
                  <p class="font-semibold text-zestBlack text-sm">
                    No Shipping Available
                  </p>
                  <ng-template #ownshipping>
                    <p class="font-semibold text-zestBlack text-sm">Shipping</p>
                  </ng-template>
                  <p
                    class="text-xs font-normal text-zestTertiary-700 pt-2 leading-4"
                  >
                    Due to the fact that this merchant does not have shipping fees
                    established for their store, you might not be able to complete
                    your purchase.
                  </p>
                  <ng-template #owntext>
                    <p
                      class="text-xs font-normal text-zestTertiary-700 pt-2 leading-4"
                    >
                      Please note that this merchant only offers in-store pickups.
                      To pickup your order,
                    </p>
                  </ng-template>
                  <p
                    class="text-zestTertiary-700 font-normal text-xs pt-2 leading-4"
                  >
                    Please contact merchant via <br />
                    <span>Email:</span>
                    <br />
                    <span>Phone: </span>
                  </p>
                </div>
              </div>
              <button
                type="submit"
                [disabled]="!checkoutInfo.valid"
                class="bg-zestBlack text-white text-base rounded py-3 px-6 mt-6 w-full font-semibold disabled:bg-gray-300 disabled:cursor-not-allowed"
              >
                Place Order
              </button> -->
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
